import React from 'react'
import ReactDOM from 'react-dom'

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { Login, Track } from './views'
import InvoiceDetail from './views/login/Invoice_details'
import { AuthProvider } from './context/auth'
import { PanelRoute } from './routes'
import { toast } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'
import './index.css'
import { requestNotificationPermission } from './lib/notification'

const App = () => (
	<AuthProvider>
		<Router>
			<Switch>
				<Route path="/login" component={Login} />
				<Route path="/track/:tripId" component={Track} />
				<Route exact path="/invoice/:invoice_id" component={InvoiceDetail} />
				<PanelRoute />
			</Switch>
		</Router>
	</AuthProvider>
)

ReactDOM.render(<App />, document.getElementById('root'))

toast.configure({
	autoClose: 8000,
	draggable: false,
	position: toast.POSITION.BOTTOM_RIGHT
})

setTimeout(requestNotificationPermission, 5000)
