import styled from 'styled-components';
import { DisplayMapButton } from '../../History/HistoryItem';

export const MapButton = styled(DisplayMapButton)`
	top: 2rem;
	right: 0.5rem;
	padding: 0.4rem;
`

export const Footer = styled.div`
	margin-top: 1rem;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	flex-wrap: wrap;
`