import React, { useCallback } from 'react'
import { FaMotorcycle, FaBicycle } from 'react-icons/fa'
import { IconType } from 'react-icons/lib/cjs'
import { cx } from '../../../lib/classnames'
import styled from '@emotion/styled'
import { Container, Label } from './styles'

interface Props {
	value: string | null
	updateCallback(e: string): void
}

const types: { label: string; type: string; icon: IconType }[] = [
	{
		label: 'Moto',
		type: '5c48c5ded8f8a95a31425b23',
		icon: FaMotorcycle
	},
	{
		label: 'Bicicleta',
		type: '5c61fdca8f81a04505e2d6a8',
		icon: FaBicycle
	}
]

export const DeliveryType = React.memo(({ value, updateCallback }: Props) => {
	const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		updateCallback(e.target.value)
	}, [])

	return (
		<Container>
			{types.map(({ label, type, icon: Icon }) => (
				<Label key={type} className={cx({ active: type === value })}>
					<Icon />
					<span>{label}</span>
					<input
						hidden
						id={type}
						type="radio"
						value={type}
						onChange={onChange}
						checked={type === value}
					/>
				</Label>
			))}
		</Container>
	)
})
