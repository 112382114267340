import React from 'react'
import styled from '@emotion/styled'
import { useFormContext, ConfirmationTypeOptions, PaymentTypeOptions } from '../../../../context/form'
import {
	FaUser,
	FaPhone,
	FaSearchLocation,
	FaPlus,
	FaTimes,
	FaHandHolding,
	FaMapSigns
} from 'react-icons/fa'
import { PlaceInput } from '../../../../components/PlaceInput'
import { CustomInput } from '../../../../components/Input'
import { ConfirmationType } from '../ScheduleModal/SelectType'
import { PaymentType } from '../../../../context/form/form.types'
import { cx } from '../../../../lib/classnames'
import { AddButton, ButtonContainer, Destination, DestinationContainer, DoubleContainer, OneContainer, OneContainerDisabled, ProductInfo, RemoveButton, TextArea } from './styles'

export const OrderList = () => {
	const {
		state,
		onSelectType,
		onChangeAddress,
		onSelectAddress,
		disabledInput,
		onChange,
		removeValue,
		addValue
	} = useFormContext()

	return (
		<DestinationContainer>
			{state.values.map((info, idx) => (
				<Destination key={idx}>
					<PlaceInput
						value={info.destiny}
						placeholder="Endereço"
						icon={<FaSearchLocation />}
						disabled={disabledInput}
						onChange={onChangeAddress(idx)}
						onSelect={onSelectAddress(idx)}
					/>

					<OneContainer>
						<CustomInput
							icon={<FaMapSigns />}
							disabled={disabledInput}
							value={info.address_complement}
							onChange={onChange(idx, 'address_complement')}
							placeholder="Complemento Endereço"
							autoComplete="off"
						/>
					</OneContainer>

					<OneContainer>
						<CustomInput
							required
							icon={<FaUser />}
							value={info.name}
							disabled={disabledInput}
							onChange={onChange(idx, 'name')}
							placeholder="Nome Sobrenome"
							autoComplete="off"
						/>
					</OneContainer>
					<OneContainer>
						<CustomInput
							required
							icon={<FaPhone />}
							disabled={disabledInput}
							value={info.phone}
							onChange={onChange(idx, 'phone')}
							placeholder="Telefone Celular"
							autoComplete="off"
						/>
					</OneContainer>
					<OneContainerDisabled>
						<ConfirmationType
							label="Confirmação"
							icon={FaHandHolding}
							options={ConfirmationTypeOptions}
							value={info.confirmationType}
							updateCallback={onSelectType(idx, 'confirmationType')}
						/>
					</OneContainerDisabled>
					<OneContainer>
						<ConfirmationType
							label="Tipo de Pagamento"
							icon={FaHandHolding}
							value={info.paymentType}
							options={PaymentTypeOptions}
							updateCallback={onSelectType(idx, 'paymentType')}
						/>
						<div style={{ fontSize: 11, fontStyle: 'underline', color: '#FC0' }}>* Dinheiro, Crédito e Débito considera o retorno do entregador.</div>
					</OneContainer>

					<DoubleContainer className={cx({ hidden: info.paymentType === PaymentType.CARD })}>
						<CustomInput
							value={info.price}
							onChange={onChange(idx, 'price')}
							disabled={disabledInput}
							placeholder="Preço (R$)"
						/>
						<CustomInput
							value={info.money_change}
							onChange={onChange(idx, 'money_change')}
							disabled={disabledInput || info.paymentType !== PaymentType.CASH}
							placeholder="Troco (R$)"
						/>
					</DoubleContainer>

					<ProductInfo>
						<CustomInput
							value={info.height}
							onChange={onChange(idx, 'height')}
							disabled={disabledInput}
							placeholder="Altura (cm)"
						/>
						<CustomInput
							value={info.width}
							onChange={onChange(idx, 'width')}
							disabled={disabledInput}
							placeholder="Comprimento (cm)"
						/>
						<CustomInput
							value={info.length}
							onChange={onChange(idx, 'length')}
							disabled={disabledInput}
							placeholder="Largura (cm)"
						/>
					</ProductInfo>
					<TextArea
						rows={2}
						value={info.description}
						disabled={disabledInput}
						onChange={onChange(idx, 'description')}
						placeholder="Observação"
					/>
					<RemoveButton onClick={removeValue(idx)}>
						<FaTimes />
					</RemoveButton>
				</Destination>
			))}
			<ButtonContainer>
				<AddButton onClick={addValue} disabled={disabledInput}>
					<FaPlus />
				</AddButton>
			</ButtonContainer>
		</DestinationContainer>
	)
}
