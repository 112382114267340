import styled from 'styled-components';


export const Wrapper = styled.div`
	display: inline-flex;
	position: absolute;
	vertical-align: top;
	right: -8px;
	top: -10px;
`

export const Trigger = styled.button`
	appearance: none;
	background: none;
	border: none;
	outline: none;
	padding: 10px;
	display: flex;
	cursor: pointer;
	justify-content: space-between;
	font-size: 0.85rem;
	font-weight: 600;
	color: #23d160!important;
	padding: 4px 6px;
	border-radius: 4px;

	&:disabled {
		background: #e0e0e0;
	}

	& svg {
		align-self: center;
		vertical-align: middle;
		margin-left: 4px;
	}
`

export const Menu = styled.div`
	position: absolute;
	display: block;
	right: 0;
	min-width: 12rem;
	padding-top: 2px;
	top: 100%;
	z-index: 20;
`

export const ItemContainer = styled.div`
	background-color: #fff;
	border-radius: 4px;
	padding-bottom: 0.5rem;
	padding-top: 0.5rem;
	box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
	display: flex;
	flex-direction: column;
`

export const Item = styled.button`
	appearance: none;
	padding: 6px 10px;
	text-align: left;
	background: none;
	cursor: pointer;
	font-size: 1rem;
	color: var(--fooy-background);
	border: none;

	&:hover {
		background: #eee;
		color: black;
	}

	&:disabled {
		pointer-events: none;
		cursor: not-allowed;
		opacity: 0.1;
	}

	&.negative:hover {
		background: #c62828;
		color: white;
	}

	&.positive:hover {
		background: #2e7d32;
		color: white;
	}

	& span {
		margin-left: 0.5rem;
	}
`

export const ItemLink = Item.withComponent('a')