import styled from 'styled-components'

export const Label = styled.label`
	display: flex;
	background: #1f2128;
	position: relative;
	padding: 8px;
	border: none;
	border-radius: 8px;

	& svg {
		margin-right: 5px;
		margin-top: 1px;
	}
`

export const Input = styled.input`
	flex: 1;
	order: 1;
	width: 10px;
	border: none;
	outline: none;
	background: none;
	appearance: none;
	font-size: 1rem;
	color: white;

	&:focus + svg {
		color: #fff;
	}

	&:required + svg {
		color: var(--fooy-cancel);
	}
`
