import React, { useCallback, useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { IoMdPower, IoIosStats, IoIosNotifications, IoMdClose, IoMdCash, IoMdLink, IoMdPhoneLandscape, IoMdContact, IoMdDoneAll, IoMdBarcode, IoIosArchive, IoIosFolder, IoMdCopy, IoMdQrScanner } from 'react-icons/io'
import { useAuth } from '../../../../context/auth'
import { toast } from 'react-toastify'
import { client } from '../../../../context/Client'
import {
	Response
} from '../../../../context/responses.type'

import QRCodePix from '../../../../assets/pix.png'
import copy from 'copy-to-clipboard';


export const AdminFunctions = () => {
	const { state: { user }, logout } = useAuth()
	const [invoices, setinvoices] = useState([{
		_id: "",
		type: "",
		payday: "",
		description: "",
		done: false,
		value: 0,
		category: "",
		city: "",
		company: "",
		provider: null,
		trips: [""],
		due_date: "",
		attachment: { emv_payload: "" },
		attachment_value: ""
	}]);

	const [openinvoices, setOpeninvoices] = useState(false);
	const [systemActive, setSystemActive] = useState(true);

	const today = new Date();
	const currentday = today.toLocaleString('pt-BR');

	const [selectedInvoce, setSelectedInvoce] = useState({
		_id: "",
		type: "",
		payday: "",
		description: "",
		done: false,
		value: 0,
		category: "",
		city: "",
		company: "",
		provider: null,
		trips: [""],
		due_date: "",
		attachment: { emv_payload: "" },
		attachment_value: ""
	});

	const checkSystemActive = useCallback(() => {
		if (user!.company_object.is_active > 0) { // Sistema ativo forçado.
			setSystemActive(true);
		} else if (user!.company_object.is_active < 0) {  // Sistema bloqueado forçado.
			setSystemActive(false); // Ativa bloqueio de sistema
			setOpeninvoices(true); // Abre pagina de faturas
		} else {
			let open_invoices = invoices.filter(inv => inv.value > 0 && !inv.done);
			switch (true) {
				case open_invoices.length > 1: // mais de uma fatura em aberto. 
					setSystemActive(false); // Ativa bloqueio de sistema
					setOpeninvoices(true); // Abre pagina de faturas
					break;
				case open_invoices.length == 1: //somente uma fatura em aberto, verificar se esta vencida
					let due_date_day = new Date(open_invoices[0].due_date!); //data de vencimento da fatura
					if (new Date() > due_date_day) { //fatura está vencida ?
						setSystemActive(false); // Ativa bloqueio de sistema
						setOpeninvoices(true); // Abre pagina de faturas
					} else {
						setSystemActive(true); // Desativa bloqueio de sistema
					}
					break;
				default:
					setSystemActive(true); // Desativa bloqueio de sistema
					break;
			}
		}
	}, invoices);

	const fetchInvoices = (refresh = false) => {
		if (!user) return
		try {
			if (!refresh) setinvoices([]);
			client.get<Response>(`delivery/v1.1/invoices/${user.company}`).then(response => {
				if (response.success) {
					setinvoices(response.data);
				} else throw new Error('Não foi possivel carregar as faturas')
			})
		} catch (err) {
			console.log(err)
		}
	}

	const copyToClipboard = async () => {
		var text = selectedInvoce.attachment.emv_payload;
		await copy(text);
		toast.success("Código PIX Copiado", { position: 'top-right' });
	}

	useEffect(() => {
		checkSystemActive();
	}, invoices);

	useEffect(() => {
		fetchInvoices();
		setInterval(() => fetchInvoices(true), 10 * 1000);
	}, [user])


	return (
		<>
			<div>
				{invoices.filter(inv => inv.value > 0 && !inv.done).length ?
					<LinkOpeninvoices href="#" onClick={() => setOpeninvoices(true)}>
						<IoIosNotifications />
						<span className="mobile-hidden">&nbsp;&nbsp;{invoices.filter(inv => !inv.done).length}&nbsp;Fatura{invoices.filter(inv => !inv.done).length == 1 ? '' : 's'}&nbsp;em&nbsp;aberto!</span>
					</LinkOpeninvoices>
					:
					<Linkinvoices href="#" onClick={() => setOpeninvoices(true)}>
						<IoIosFolder />
						<span className="mobile-hidden">&nbsp;Minhas&nbsp;faturas</span>
					</Linkinvoices>}
				{/* <LinkAdmin href="https://painel.vaay.app/" target="_blank" >
					<IoIosStats />
					<span className="mobile-hidden"> Painel</span>
				</LinkAdmin> */}
				<IconButton onClick={logout}>
					<IoMdPower />
				</IconButton>
			</div>
			<InvoicesPainel style={openinvoices ? { display: "inherit" } : { display: "none" }}>
				{systemActive ? <button onClick={() => setOpeninvoices(false)} style={{ backgroundColor: "#ff3860", color: '#FFF', padding: "8px 12px", borderRadius: 100, border: "none", cursor: 'pointer' }}> <IoMdClose style={{ marginBottom: -2 }} /> Fechar </button>
					:
					<>
						<div style={{ backgroundColor: "#ff3860", padding: "12px 6px", color: '#FFF' }}>
							<div style={{ fontSize: 16, fontWeight: 'bold', textTransform: 'uppercase' }}>Sistema Bloqueado por falta de pagamento!</div>
							<div style={{ fontSize: 11, color: '#' }}>Realize o pagamento das faturas em aberto para continuar a utilizar nosos serviços.</div>
						</div>
					</>
				}
				<div style={{ fontSize: 28, marginTop: 20, marginBottom: 5 }}><strong>MINHAS FATURAS</strong></div>
				<div style={{ padding: 5, paddingTop: 10 }}>
					<table style={{ width: "100%", maxWidth: "900px", margin: "0 auto", marginBottom: 30 }}>
						<thead style={{ backgroundColor: "#EEE", borderBottom: "solid 2px #AAA" }}>
							<tr>
								<th style={{ padding: "6px 10px" }}>Vencimento</th>
								<th style={{ padding: "6px 10px" }} className={'mobile-hidden'}>Descrição</th>
								<th style={{ padding: "6px 10px" }}>Valor</th>
								<th style={{ padding: "6px 10px" }}>Status</th>
								<th style={{ padding: "6px 10px" }}>&bull;&bull;&bull;</th>
							</tr>
						</thead>
						<tbody style={{ fontSize: 14 }}>
							{invoices.filter(inv => inv.value > 0).map(inv => (
								<tr>
									<td style={{ padding: "6px 10px", borderBottom: "solid 1px #EEE" }}>{new Date(inv.due_date).toLocaleDateString()}</td>
									<td style={{ padding: "6px 10px", borderBottom: "solid 1px #EEE" }} className={'mobile-hidden'}>{inv.description}</td>
									<td style={{ padding: "6px 10px", borderBottom: "solid 1px #EEE" }}>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(inv.value)}</td>
									<td style={{ padding: "6px 10px", borderBottom: "solid 1px #EEE" }}>{inv.done ? <span style={{ background: "rgb(76,225,182)", padding: '4px 8px', color: '#FFF', borderRadius: 6 }}>Pago</span> : (today > new Date(inv.due_date!) ? <span style={{ background: "#ff3860", padding: '4px 8px', color: '#FFF', borderRadius: 6 }}>Vencida</span> : <span style={{ background: "#f4b835", padding: '4px 8px', color: '#FFF', borderRadius: 6 }}>Pendente</span>)}</td>
									<td style={{ padding: "3px", borderBottom: "solid 1px #EEE" }}>
										{inv.done ? <a href={`./invoice/${inv._id}`} target={'_blank'}><button style={{ cursor: "pointer", backgroundColor: "#272b31", color: "#FFF", padding: "8px 12px", borderRadius: 100, border: "none", whiteSpace: 'nowrap' }}> <IoMdLink style={{ marginBottom: -2 }} /> Detalhes </button></a>
											: <button onClick={() => setSelectedInvoce(inv)} style={{ cursor: "pointer", backgroundColor: "#11cf99", color: "#FFF", padding: "8px 12px", borderRadius: 100, border: "none", marginRight: 5, whiteSpace: 'nowrap' }}> <IoMdCash style={{ marginBottom: -2 }} /> Pagar </button>}
									</td>
								</tr>
							))}
						</tbody>
					</table>
					{selectedInvoce.value > 0 ?
						<div className={'row'} style={{ margin: "0 auto", backgroundColor: "#F5F5F5", padding: 20, borderRadius: 12, maxWidth: "900px" }}>
							<div className={"col-md-8"} style={{ textAlign: 'left', padding: '20px 10px' }}>
								<div style={{ fontSize: 20 }}><b>RESUMO DA FATURA</b></div>
								<div style={{ marginBottom: 15, fontSize: 14 }}>{selectedInvoce.description}</div>
								<div style={{ marginBottom: 15, fontSize: 14 }}>
									<div style={{ fontSize: 11 }}>VENCIMENTO</div>
									{new Date(selectedInvoce.due_date).toLocaleDateString()}&nbsp;
									<small style={{ fontSize: 11 }}>{(today > new Date(selectedInvoce.due_date!) ? <span style={{ background: "#ff3860", padding: '4px 8px', color: '#FFF', borderRadius: 6 }}>VENCIDA</span> : <span style={{ background: "#f4b835", padding: '4px 8px', color: '#FFF', borderRadius: 6 }}> Pendente</span>)}</small>
								</div>
								<div style={{ fontSize: 11 }}>TOTAL A PAGAR</div>
								<div style={{ fontSize: 28 }}><b>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(selectedInvoce.value)}</b></div>
								<div style={{ marginTop: 15 }}><a href={`./invoice/${selectedInvoce._id}`} target={'_blank'}><button style={{ cursor: "pointer", backgroundColor: "transparent", color: "#272b31", padding: "8px 12px", borderRadius: 100, border: "solid 2px #272b31", whiteSpace: 'nowrap' }}> <IoMdLink style={{ marginBottom: -2 }} /> Ver fatura completa </button></a></div>
							</div>
							<div className={"col-md-4"}>
								<div style={{ fontSize: 11 }}>PAGUE COM QRCODE PIX</div>
								<img src={selectedInvoce.attachment_value.length > 200 ? `data:image/png;base64,${selectedInvoce.attachment_value}` : QRCodePix} style={{ width: "100%", maxWidth: 180, padding: 10, borderRadius: 6, backgroundColor: "#FFF", marginTop: 15, marginBottom: 5 }} />
								<div><button onClick={() => copyToClipboard()} style={{ cursor: "pointer", backgroundColor: "#111", color: "#FFF", padding: "8px 12px", borderRadius: 100, border: "none" }}> <IoMdCopy style={{ marginBottom: -2 }} /> Copiar Código PIX</button></div>
							</div>
						</div>
						: null}
				</div>
				<div style={{ fontSize: 12, marginTop: 10 }}>
					Por favor, em caso de faturas que já foram pagas, informe nosso financeiro sobre o pagamento ou aguarde até identificarmos.<br />
					Caso o pagamento tenha sido realizado através de boleto bancário, leva até 3 dias para identificarmos no sistema.<br />
					<span style={{ color: "#ff3860" }}><strong style={{ color: "#ff3860" }}>Atenção:</strong> faturas em aberto pode ocasionar bloqueio do sistema.</span>
					<div>
						<a href="https://wa.me/558182942655" target="_blank"><button style={{ cursor: "pointer", backgroundColor: "#11cf99", color: "#FFF", padding: "8px 12px", borderRadius: 100, border: "none", marginTop: 15 }}> <IoMdContact style={{ marginBottom: -2 }} /> Falar com Financeiro </button></a>
					</div>
				</div>
			</InvoicesPainel>
		</>
	)
}

const InvoicesPainel = styled.div`
	background-color:#FFF;
	position:fixed;
	top:0;
	left:0;
	width:100%;
	height:100%;
	color:#272b31;
	z-index:100;
	padding:15px;
	text-align:center;
	overflow-y:auto;
	padding-bottom:70px;
`

const Linkinvoices = styled.a`
	background-color: #EEE;
	border: none;
	color: rgb(39,43,50);
	padding: 6px 22px;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border: none;
	border-radius: 100px;
	cursor: pointer;
	position: absolute;
	top: 16px;
	right: 70px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-align-items: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	font-size: 18px;
	font-weight: 600;
	z-index: 99;
	-webkit-transition: all 300ms;
	transition: all 300ms;

	&:hover {
		background: rgb(39,43,50);
		color: rgb(76,225,182);
	}
`

const LinkOpeninvoices = styled.a`
	background-color: #f4b835;
	border: none;
	color: rgb(39,43,50);
	padding: 6px 22px;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border: none;
	border-radius: 100px;
	cursor: pointer;
	position: absolute;
	top: 16px;
	right: 70px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-align-items: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	font-size: 18px;
	font-weight: 600;
	z-index: 99;
	-webkit-transition: all 300ms;
	transition: all 300ms;

	&:hover {
		background: rgb(39,43,50);
		color: rgb(76,225,182);
	}
`

const LinkAdmin = styled.a`
	background-color: rgb(76,225,182);
	border: none;
	color: rgb(39,43,50);
	padding: 6px 22px;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border: none;
	border-radius: 100px;
	cursor: pointer;
	position: absolute;
	top: 16px;
	right: 70px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-align-items: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	font-size: 18px;
	font-weight: 600;
	z-index: 99;
	-webkit-transition: all 300ms;
	transition: all 300ms;

	&:hover {
		background: rgb(39,43,50);
		color: rgb(76,225,182);
	}
`
const IconButton = styled.button`
	appearance: none;
	border: none;
	border-radius: 50%;
	background: transparent;
	cursor: pointer;
	position: absolute;
	top: 10px;
	right: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 20px;
	font-weight: 600;
	padding: 10px;
	color: white;
	z-index: 99;
	transition: all 300ms;

	&:hover {
		background: white;
		color: black;
	}
`