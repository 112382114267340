import React from 'react'
import { Destination as DestinationType, PaymentType } from '../../../context/history/history.type'

import { Destination, Wrapper } from './styles'

interface Props {
	open: boolean
	destinations?: DestinationType[]
}

interface Invoice {
	payment_type: PaymentType,
	price: Number,
	money_change: Number
}

export const DisplayProviderStatus = ({ status, is_rated }: { status: number, is_rated: number }) => {
	if (is_rated == 1) {
		return <span className="has-text-success">Avaliado</span>
	}
	else {
		switch (status) {
			case 2:
				return <span className="has-text-warning">Entregador a caminho</span>
			case 4:
				return <span className="has-text-warning">Entregador chegou</span>
			case 6:
				return <span className="has-text-warning">Entrega iniciada</span>
			case 8:
				return <span className="has-text-warning">Retornando para o estabelecimento</span>
			case 9:
				return <span className="has-text-success">Entrega finalizada</span>
			default:
				return <span className="has-text-white">Aguardando confirmação</span>
		}
	}
}

export const DisplayPayment = (invoice: Invoice) => {
	switch (invoice.payment_type) {
		case PaymentType.CASH:
			return <div>
				<p>• Dinheiro</p>
				<p>• Valor: {invoice.price.toFixed(2).replace(".", ",")}</p>
				<p>• Troco: {invoice.price && invoice.money_change.toFixed(2).replace(".", ",")}</p>
			</div>
		case PaymentType.FACE_CREDIT:
			return <div>
				<p>• Crédito (Máquina)</p>
				<p>• Valor: {invoice.price && invoice.price.toFixed(2).replace(".", ",")}</p>
			</div>
		case PaymentType.FACE_DEBIT:
			return <div>
				<p>• Débito (Máquina)</p>
				<p>• Valor: {invoice.price && invoice.price.toFixed(2).replace(".", ",")}</p>
			</div>
		default:
			return <p>• Online</p>
	}
}

export const HistoryDestination = ({ open, destinations }: Props) => {
	if (open && destinations && destinations.length) {
		return (
			<Wrapper>
				{destinations.map((des, index) => (
					<Destination key={des._id}>
						<h4>Destino: {des.external_id}</h4>
						<p>
							{des.user_name}
							{des.user_phone && (
								<a
									target="_blank"
									rel="noopener"
									href={`https://web.whatsapp.com/send?phone=+55${des.user_phone}`}
									style={{ marginLeft: '0.5rem' }}
								>
									{des.user_phone}
								</a>
							)}
						</p>
						<p style={{ fontSize: 12 }}>{des.address}</p>
						<p style={{ fontWeight: 600 }}>{des.description}</p>
						<p style={{ fontWeight: 600 }}><h4>Pagamento:</h4></p>
						<DisplayPayment {...des.invoice} />
					</Destination>
				))}
			</Wrapper>
		)
	}

	return null
}
