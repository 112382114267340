import React from 'react'

import { DisplayProviderStatus, ProviderPicture } from '../../History/HistoryItem'
import { Item } from '../../History/HistoryItem/styles'
import { HistoryDestination } from '../../History/HistoryDestination'
import { ScheduleTrip } from '../../../context/trip/tripProvider.type'
import { ModalConfirm } from '../../History/ModalConfirm'
import { FaHandPaper } from 'react-icons/fa'
import { format } from 'date-fns'
import ptLocale from 'date-fns/locale/pt'
import { formatPhone } from '../../../lib/masks'
import { CancelButton, Info } from './styles'

interface Props {
	item: ScheduleTrip
	cancel(): void
}

export const ScheduleItem = ({ item, cancel }: Props) => {
	const hasProvider = Boolean(item.confirmed_provider)

	return (
		<Item>
			<HistoryDestination open={true} destinations={item.destinations} />
			<Info>
				<div>
					<h3 className="title is-6">Distância</h3>
					<p className="subtitle is-6">{item.distance} km</p>
				</div>
				<div>
					<h3 className="title is-6">Pagamento</h3>
					<p className="subtitle is-6">R$ {item.amount}</p>
				</div>
				<div>
					<h3 className="title is-6">Agendado para</h3>
					<p className="subtitle is-6">
						{format(item.scheduled_date, 'ddd D [de] MMM [as] HH:mm', {
							locale: ptLocale
						})}
					</p>
				</div>
			</Info>
			<Info>
				<div>
					<h3 className="title is-6">Status</h3>
					<p className="subtitle is-6">
						<DisplayProviderStatus status={item.status} is_rated={0} />
					</p>
				</div>
			</Info>
			{hasProvider && (
				<div style={{ display: 'flex', marginTop: '1rem' }}>
					<ProviderPicture image={item.confirmed_provider!.picture} />
					<div>
						<p style={{ marginBottom: 4, fontWeight: 600 }}>
							{item.confirmed_provider!.first_name} {item.confirmed_provider!.last_name}
						</p>
						<a
							rel="noopener"
							target="_blank"
							href={`https://web.whatsapp.com/send?phone=+55${item.confirmed_provider!.phone}`}
						>
							{formatPhone(item.confirmed_provider!.phone)}
						</a>
						<p style={{ textTransform: 'uppercase' }}>
							{item.confirmed_provider!.current_vehicle.plate_no}
						</p>
					</div>
				</div>
			)}

			<ModalConfirm
				title="Cancelar este Agendamento?"
				action={cancel}
				trigger={prop => (
					<CancelButton onClick={prop.action} className="negative">
						<FaHandPaper />
						<span>Cancelar</span>
					</CancelButton>
				)}
			/>
		</Item>
	)
}