import React, { useRef, useEffect } from 'react'

import flatpickr from 'flatpickr'
import 'flatpickr/dist/flatpickr.css'
import { Input } from './styles'

interface Prop {
	onChange(date: string): void
}

export const DatePicker = React.memo(({ onChange }: Prop) => {
	const input = useRef<HTMLInputElement>(null)

	useEffect(() => {
		flatpickr(input.current!, {
			enableTime: true,
			dateFormat: 'Y-m-d H:i',
			time_24hr: true,
			onChange: (selectedDates, dateStr, instance) => {
				if (selectedDates.length) {
					onChange(selectedDates[0].toString())
				}
			}
		})
	}, [])

	return <Input type="text" placeholder="Selecione Data e Hora" ref={input} />
})
