import numeral from 'numeral'
import 'numeral/locales'
numeral.locale('pt-br')

interface MaskChar {
	[x: number]: string
}

const digit8: MaskChar = { 0: '(', 2: ') ', 6: '-' }
const digit9: MaskChar = { 0: '(', 2: ') ', 7: '-' }
export const formatPhone = (str: string): string => {
	const clean = str.replace(/\D/g, '').split('')
	const format =
		clean.length >= 10
			? clean.reduce((p: string, v, i) => `${p}${(digit9[i] || '') + v}`, '')
			: clean.reduce((p: string, v, i) => `${p}${(digit8[i] || '') + v}`, '')
	return format.substr(0, 15)
}

export function formatPrice(str: string): string {
	const value = str.length === 1 ? `0${str}` : str
	return numeral(
		Number(parseFloat(value.replace(/[\D]/g, '').replace(/(\d\d?)$/g, '.$1')).toFixed(2))
	).format('0,0.00')
}

export function toNumber(str?: string): number {
	return !!str ? Number(str.replace(',', '.')) : 0
}
