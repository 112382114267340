import React, { useMemo } from 'react'
import styled from '@emotion/styled'
import { useFormContext } from '../../../../context/form'
import { withRouter } from 'react-router'
import { cx } from '../../../../lib/classnames'

export const DistanceModalMobile = withRouter(({ location: { pathname } }) => {
	const {
		state: { distances, distance, price }
	} = useFormContext()

	const _open = useMemo(() => Boolean(pathname === '/' && distance), [distance, pathname])

	return (
		<Container className={cx({ open: _open })}>
			<h1 className="title is-4">Distâncias</h1>
			<p className="subtitle is-6">
			{distance == null ? '' : (
				distance.split("/").length > 0 ? distance!.split("/").map((d) => { return <div>{d}</div>})
				: distance
			)}
			</p>
			<hr/>
			<h1 className="title is-4">Total</h1>
			<p className="subtitle is-6">{price ? `R$ ${price}` : "Selecione um veículo"}</p>
		</Container>
	)
})

const Container = styled.div`
	background: white;
	padding: 1rem;
	transform: translateY(-100%);
	transition: transform 300ms ease;
	display:none;
	&.open {
		transform: translateY(0);
		display:inherit;
	}
`
