import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	height: 100vh;
	background-color: #1f2128;
	transition: transform 800ms cubic-bezier(0.165, 0.84, 0.44, 1);
`

export const Panel = styled.div`
	width: 30rem;
	display: grid;
	height: 100vh;
	overflow: auto;
	overflow-x: hidden;
	grid-template-rows: 60px 1fr;
	background: var(--fooy-background);
	position: relative;
	padding: 20px;
	z-index: 1;
`

export const PanelDelivers = styled.div`
	width: 390px;
	display: grid;
	height: 100vh;
	overflow: auto;
	overflow-x: hidden;
	grid-template-rows: 60px 1fr;
	background: #333;
	position: relative;
	padding: 20px;
	z-index: 1;
	box-shadow: 20px 0px 25px -10px #00000077;
    border-left: solid 1px #444;
`

export const SplitBar = styled.div`
	width: 15px;
	z-index: 99;
	cursor: ew-resize;
	position: absolute;
	right: -7px;
	bottom: 0;
	top: 0;
`

export const MapWrapper = styled.div`
	flex: 1;
	position: relative;
`
export const WhatsAppButtonStyle = styled.button`
	border: none;
	outline: none;
	appearance: none;
	background: none;
	color:  #eee;
	font-size: 3rem;
	cursor: pointer;
	border-radius: 50%;
	padding: 0.6rem;

	width: 6rem;
	height: 6rem;
	position: absolute;
	bottom: 2rem;
	right: 3rem;

	& svg {
		transition: transform 500ms ease;
	}

	& p {
		font-size: 0.8rem;
	}

	/* &:hover {
		background: #eee;
	} */

	&:hover svg {
		transform: scale(1.5);
	}
`