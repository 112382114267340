import styled from 'styled-components';
import { keyframes } from '@emotion/core'


const ellipsis1 = keyframes`
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
`

const ellipsis2 = keyframes`
  from {
    transform: translate(0, 0);
  }
  to {
    transform: translate(19px, 0);
  }
`

const ellipsis3 = keyframes`
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0);
  }
`

export const Wrapper = styled.div`
	display: inline-block;
	position: relative;
	width: 64px;
	height: 24px;

	& div {
		position: absolute;
		top: 10px;
		width: 11px;
		height: 11px;
		border-radius: 50%;
		background: var(--fooy);
		animation-timing-function: cubic-bezier(0, 1, 1, 0);
	}
	& div:nth-child(1) {
		left: 6px;
		animation: ${ellipsis1} 0.6s infinite;
	}
	& div:nth-child(2) {
		left: 6px;
		animation: ${ellipsis2} 0.6s infinite;
	}
	& div:nth-child(3) {
		left: 26px;
		animation: ${ellipsis2} 0.6s infinite;
	}
	& div:nth-child(4) {
		left: 45px;
		animation: ${ellipsis3} 0.6s infinite;
	}
`