import styled, { css } from 'styled-components'

export const Item = styled.button`
	background: #fff;
	appearance: none;
	border: none;

	&:hover {
		cursor: pointer;
	}
`

Item.defaultProps = { className: 'dropdown-item' }

export const Wrapper = styled.div`
	display: grid;
	position: relative;
	vertical-align: top;
`

export const Trigger = styled.button`
	appearance: none;
	background: none;
	border: none;
	outline: none;
	padding: 10px;
	display: flex;
	justify-content: space-between;
	background: var(--fooy);
	font-size: 1rem;
	color: white;
	padding: 10px 8px;
	border-radius: 4px;
	transition: opacity 0.1s;

	&:hover{
		cursor: pointer;
		opacity: 0.9;
	}

	& svg {
		align-self: center;
		vertical-align: middle;
		margin-left: 4px;
	}
`

interface MenuProp {
	active: boolean
}

export const active = (prop: MenuProp) =>
	prop.active &&
	css`
		display: block;
	`

export const Menu = styled.div<MenuProp>`
	position: absolute;
	display: none;
	left: 0;
	min-width: 10rem;
	padding-top: 2px;
	top: 100%;
	z-index: 20;
	${active}
`

export const ItemContainer = styled.div`
	background-color: #fff;
	border-radius: 4px;
	padding-bottom: 0.5rem;
	padding-top: 0.5rem;
	box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
`
