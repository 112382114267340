import React from 'react'
import { Container, Input} from './styles'

interface Props extends React.HTMLProps<HTMLInputElement> {
	icon?: React.ReactNode
}

export const TextInput = React.memo(({ icon, ...props }: Props) => (
	<Container>
		<Input {...props} />
		{icon}
	</Container>
))