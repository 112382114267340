import styled from 'styled-components';



export const Wrapper = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	overflow-y: scroll;
	overflow: hidden;
`

export const RequiredFields = styled.span`
	margin-bottom: 1rem;
	position: relative;
	margin-left: 1rem;
	font-size: 14px;
	color: white;

	&::before {
		content: '';
		position: absolute;
		top: 50%;
		left: -0.8rem;
		transform: translateY(-50%);
		width: 6px;
		height: 6px;
		border-radius: 3px;
		background: var(--fooy);
		/* width: */
	}
`

export const Header = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 1rem;

	& > span {
		align-self: end;
		font-size: 0.9rem;
		color: var(--fooy-sec);
	}
`

export const EmptyList = styled.p`
	position: relative;
  margin: 20px auto;
	font-size: 20px;
	color: white;
`