import styled from 'styled-components';

export const Info = styled.div`
	display: flex;
	justify-content: space-between;
	padding-top: 1rem;

	h3, p{
		color: #fff;
	}
`

export const CancelButton = styled.button`
	display: flex;
	align-items: center;
	background: #c62828;
	appearance: none;
	border: none;
	cursor: pointer;
	border-radius: 4px;

	padding: 0.3rem 0.5rem;
	position: absolute;
	right: 0.4rem;
	top: 0.4rem;

	font-size: 1rem;
	font-weight: 600;
	color: white;

	opacity: 0.9;

	&:hover {
		opacity: 1;
	}

	& span {
		margin-left: 0.5rem;
	}
`
