export interface Overlay extends google.maps.OverlayView {
	show(): void
	hide(): void
}

export const DeferOverlay = () =>
	class Overlay extends google.maps.OverlayView implements Overlay {
		div?: HTMLElement | null
		map?: google.maps.Map
		position?: google.maps.LatLng

		constructor(map: google.maps.Map, position: google.maps.LatLng) {
			super()

			this.map = map
			this.position = position
			this.setMap(map)
		}

		onAdd() {
			const container = document.createElement('div')
			container.style.visibility = 'visible'
			container.classList.add('overlay_container')

			this.div = container

			const panes = this.getPanes()
			panes.overlayLayer.appendChild(container)
		}

		draw() {
			if (this.position && this.div) {
				const projection = this.getProjection()
				const pos = projection.fromLatLngToDivPixel(this.position)

				const container = this.div
				container.style.left = `${pos.x}px`
				container.style.top = `${pos.y}px`
			}
		}

		onRemove() {
			if (this.div && this.div.parentNode) {
				this.div && this.div.parentNode.removeChild(this.div)
				this.div = null
			}
		}

		show() {
			if (this.div) {
				this.div.style.visibility = 'visible'
			}
		}

		hide() {
			if (this.div) {
				this.div.style.visibility = 'hidden'
			}
		}
	}
