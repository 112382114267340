import styled from 'styled-components'

export const Container = styled.div`
	display: flex;
	margin-top: 1rem;
	background: #1f2128;
	border-radius: 4px;
	transition: all 200ms linear;
	position: relative;

	& > span:first-child {
		color: #757575;
		position: absolute;
		line-height: 0.85rem;
		font-size: 0.8rem;
		transform: translateY(-120%);
		border-radius: 4px;
		padding: 0 6px;
		top: 0;
	}
`

export const Trigger = styled.button`
	appearance: none;
	background: none;
	outline: none;
	border: none;
	flex: 1;

	display: flex;
	align-items: center;
	justify-content: flex-end;

	color: white;
	padding: 10px;
	cursor: pointer;
	font-weight: 600;
	border-radius: 4px;
	font-size: 0.85rem;

	& span {
		flex: 1;
		text-align: left;
	}
`

export const Menu = styled.div`
	position: absolute;
	left: 0;
	top: 105%;

	display: flex;
	flex-direction: column;

	border: 2px solid var(--fooy-background);
	border-radius: 4px;
	background: var(--fooy-sec-background);
	box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.14);

	z-index: 20;
	min-width: 12rem;
	padding: 5px 0;
`

export const Item = styled.button`
	flex: 1;

	appearance: none;
	background: transparent;
	color: white;
	border: none;
	cursor: pointer;

	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 0.7rem;
	font-size: 1rem;

	& span {
		padding: 0 10px;
		/* font-weight: 600; */
	}

	&:hover {
		background: rgba(0, 0, 0, 0.2);
	}
`
