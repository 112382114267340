import styled from 'styled-components';

export const Input = styled.input`
	font-size: 1.1rem;
	padding: 0.6rem;

	border: none;
	box-shadow: 2px 2px 2px var(--fooy-dark);
	background: var(--fooy-background);
	border-radius: 4px;
	color: white;
`