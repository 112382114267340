import { TOKEN, isDevelopment } from './Constants'
import { Response } from './responses.type'

type method = 'GET' | 'POST' | 'PUT' | 'DELETE'

class Client {
	get<T>(endpoint: string, data?: any): Promise<T> {
		return this.request('GET', endpoint, data)
	}

	post<T>(endpoint: string, data?: any): Promise<T> {
		return this.request<T>('POST', endpoint, data)
	}

	put<T>(endpoint: string, data?: any): Promise<T> {
		return this.request<T>('PUT', endpoint, data)
	}

	delete<T>(endpoint: string, data?: any): Promise<T> {
		return this.request<T>('DELETE', endpoint, data)
	}

	// fetch<T>(endpoint)

	async request<T>(method: method = 'GET', endpoint: string, body?: any): Promise<T> {
		if (isDevelopment) {
			console.log(`${method} url: ${endpoint}`)
		}

		const config = { method } as RequestInit
		const header = new Headers()

		try {
			const token = window.sessionStorage.getItem(TOKEN)

			if (token) {
				header.append('Authorization', `JWT ${token}`)
			}

			// const route = new URL(`http://localhost:4000/${endpoint}`)
			const route = new URL(`https://api.vaay.app/${endpoint}`)


			if (method === 'GET' && body) {
				Object.keys(body).forEach(key => {
					const value = body[key]
					if (value !== undefined) route.searchParams.append(key, value)
				})
			} else if (body && body instanceof FormData) {
				config.body = body
			} else {
				config['body'] = JSON.stringify(body)
				header.append('Content-Type', 'application/json')
			}

			config['headers'] = header

			const promise: T = await window.fetch(route.href, config).then(res => res.json())

			return promise
		} catch (err) {
			console.log(err)
			return errorMessage(err.message)
		}
	}
}

export function errorMessage(message: string): any {
	return {
		success: false,
		data: null,
		error: {
			message,
			status: 408,
			code: 'request/timeout-reached'
		}
	}
}

export const client = new Client()
