type coords = {
	lat: number
	lng: number
}

function errorCallback(position: any): void {
	const { code, message } = position

	const err =
		code === 1 ? 'PERMISSION_DENIED' : code === 2 ? 'POSITION_UNAVAILABLE' : code === 3 ? 'TIMEOUT' : 'UNKNOWN_ERROR'

	console.log(`%c ${err}: ${message}`, 'color: magenta; font-size: 18px')
}

export default (): Promise<google.maps.LatLngLiteral> => {
	return new Promise((resolve, rejects) => {
		if ('geolocation' in navigator) {
			navigator.geolocation.getCurrentPosition((position: any) => {
				const {
					coords: { latitude, longitude }
				} = position
				resolve({ lat: latitude, lng: longitude })
			}, errorCallback)
		} else {
			rejects(false)
		}
	})
}
