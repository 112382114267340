import { toast } from 'react-toastify'
import LOGO from '../assets/logo_notification.png'

export function requestNotificationPermission() {
	return new Promise((resolve:any, reject) => {
		if (!('Notification' in window)) {
			toast.warn('Seu navegador não suporta notificações.')
		} else if (Notification && Notification.permission === 'default') {
			Notification.requestPermission(permission => {
				if (permission !== 'granted') {
					toast.error('É necessário ativar as notificações.')
					reject()
				} else {
					resolve()
				}
			})
		}
	})
}

export function displayNotification(title: string, message: string) {
	if (Notification.permission === 'granted') {
		const notification = new Notification(title, { body: message, icon: LOGO })
		notification.onclick = () => window.focus()
	} else if (Notification.permission !== 'denied') {
		requestNotificationPermission()
	} else {
		toast.info(message)
	}
}
