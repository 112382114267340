import { client } from '../Client'
import { ResponseLogin, ResponseWithUser } from './auth.schema'
import { TOKEN, USERDATA } from '../Constants'

function handleUserResponse<T extends ResponseLogin>(response: T): T {
	if (response.success) {
		window.sessionStorage.setItem(TOKEN, response.data.token)
		window.sessionStorage.setItem(USERDATA, JSON.stringify(response.data.user))
	}

	return response
}

export function login(form: any) {
	return client
		.post<ResponseLogin>('api/company/v1/company_admins/signin', form)
		.then(handleUserResponse)
}

export function logout() {
	window.sessionStorage.removeItem(TOKEN)
	return Promise.resolve(null)
}

export function getToken() {
	return window.sessionStorage.getItem(TOKEN)
}

export function getUser(): Promise<null | ResponseWithUser> {
	const token = getToken()

	if (!token) {
		return Promise.resolve(null)
	}

	return client.get<ResponseWithUser>('api/company/v1/company_admins/me').catch(err => {
		return logout()
	})
}
