import { useState, useCallback, useRef, useEffect } from 'react'

export function useInput(
	initial: string | (() => string)
): [string, (e: React.ChangeEvent<HTMLInputElement>) => void] {
	const [value, setValue] = useState(initial)

	const onChange = useCallback(e => setValue(e.target.value), [])

	return [value, onChange]
}

export const useToggle = (initial: boolean) => {
	const [active, setActive] = useState(initial)
	const toggle = useCallback(() => setActive(v => !v), [])
	const disabled = useCallback(() => setActive(false), [])
	const enabled = useCallback(() => setActive(true), [])
	return { active, toggle, disabled, enabled }
}

export function useDebounceCallback<T>(value: T, delay: number, cb: (t: T) => any) {
	const didMount = useRef(false)
	const timeout = useRef<any>(0)

	useEffect(() => {
		if (didMount.current) {
			timeout.current = setTimeout(() => cb(value), delay)
		} else didMount.current = true

		return () => {
			clearTimeout(timeout.current)
		}
	}, [value])
}

export function useDebounceCallbackCheck<T>(
	value: T,
	options: {
		delay: number
		runFirst?: boolean
	},
	checkCondition: () => any,
	cb: (t: T) => any
) {
	const didMount = useRef(false)
	const timeout = useRef<any>(0)
	const wasExecuted = useRef<boolean>(false)

	useEffect(() => {
		if (didMount.current) {
			if (checkCondition() && options.runFirst && !wasExecuted.current) {
				wasExecuted.current = true
				cb(value)
			} else {
				timeout.current = setTimeout(() => cb(value), options.delay || 500)
			}
		} else didMount.current = true

		return () => {
			clearTimeout(timeout.current)
		}
	}, [value])
}

export function useStopPropagation(callback: () => void) {
	const _action = useRef((e: React.MouseEvent<any>) => {
		e.stopPropagation()
		callback()
	})

	return _action.current
}

export function useLoadingState(callbackAction: () => Promise<void>) {
	const { active: loading, disabled, enabled } = useToggle(false)

	const startAction = useCallback(() => {
		enabled()
		callbackAction().then(disabled)
	}, [callbackAction])

	return {
		loading,
		startAction
	}
}
