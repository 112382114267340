import React, { useContext, useState, useCallback, useRef } from 'react'
import {
	ProviderInfo,
	PlannedTrip,
	ScheduleOptionsCallback,
	ScheduleTrip
} from './tripProvider.type'
import {
	ResponseWithTrip,
	Response,
	ResponseWithPlannedTrip,
	ResponseWithScheduleTrip,
	ResponseWithProvider
} from '../responses.type'
import { _createTrip, parseProvider } from './util'
import { Trip } from '../history/history.type'
import { useToggle } from '../../lib/hooks'
import { useAuth } from '../auth'
import { client } from '../Client'
import { toast } from 'react-toastify'
import Map from '../../lib/Map'

interface Context {
	trip: Trip | null
	provider: ProviderInfo | null
	loading: boolean
	searching: boolean
	cancelling: boolean
	isModalOpen: boolean
	plannedTrips: PlannedTrip[]
	schedulesTrips: ScheduleTrip[]
	motoboyNotFound: boolean
	getSchedules(costCenterId: string): void
	getPlannedTrip(costCenterId: string): void
	createTrip(data: any, cb?: () => void): Promise<void>
	cancelCurrentTrip(): void
	closeModal(): void

	cancelPlannedTrip(tripPlanId: string, costCenterId: string): any
	confirmPlannedTrip(tripPlanId: string, costCenterId: string, typeId: string): any

	// schedule routes
	makeScheduleTrip(data: any, options?: ScheduleOptionsCallback): void
	// confirmScheduleTrip(tripScheduleId: string, costCenterId: string, typeId: string): any
	cancelScheduleTrip(tripScheduleId: string, costCenterId: string): any
	setMotoboyNotFound: React.Dispatch<React.SetStateAction<boolean>>
}

const TripContext = React.createContext({} as Context)

export function useTrip() {
	const context = useContext(TripContext)
	if (context === undefined) {
		throw new Error('useTrip can only be used within TripProvider')
	}
	return context
}

export const TripProvider: React.SFC = props => {
	const {
		state: { user }
	} = useAuth()

	const { active: loading, enabled: loadStart, disabled: loadEnd } = useToggle(false)
	const { active: searching, enabled: searchStart, disabled: searchEnd } = useToggle(false)
	const { active: cancelling, enabled: cancellingStart, disabled: cancellingEnd } = useToggle(false)
	const { active: isModalOpen, enabled: openModal, disabled: closeModal } = useToggle(false)

	const [provider, setProvider] = useState<ProviderInfo | null>(null)
	const [trip, setTrip] = useState<Trip | null>(null)
	const [motoboyNotFound, setMotoboyNotFound] = useState<boolean>(false)
	const wasTripCancel = useRef<boolean>(false)

	const _checkTripStatus = (trip_id: string, user_id: string): Promise<ProviderInfo | null> => {
		return new Promise(resolve => {
			const started = Date.now()

			const checkStatus = async () => {
				if (wasTripCancel.current) {
					wasTripCancel.current = false
					resolve(null)
				}

				let duration = Date.now() - started

				if (duration > 120000) resolve(null)

				const response = await client.get<ResponseWithProvider>(
					`api/company/v1/users/${user_id}/trip/${trip_id}/status`
				)

				if (!response.success) {
					toast.error('Nenhum entregador disponível, tente novamente...')
					setMotoboyNotFound(true)
					Map.hideSearch()
					searchEnd()
					return resolve(null)
				}

				const { is_provider_accepted, is_trip_cancelled, provider } = response.data

				if (is_trip_cancelled === 1) {
					toast.error('Nenhum entregador disponível, tente novamente...')
					Map.hideSearch()
					searchEnd()
					return resolve(null)
				} else if (is_provider_accepted === 2) {
					setTimeout(checkStatus, 2000)
				} else if (is_provider_accepted === 1 && provider) {
					Map.hideSearch()
					setMotoboyNotFound(false)
					searchEnd()
					return resolve(parseProvider(response.data))
				}
			}
			checkStatus()
		})
	}

	const cancelCurrentTrip = useCallback(async () => {
		if (!user || !trip) return

		try {
			cancellingStart()
			const url = `delivery/v1.1/trip/${trip._id}/cancel`
			await client.post<Response>(url)
			wasTripCancel.current = true

			cancellingEnd()
			setProvider(null)
		} catch (err) {
			toast.error('Corrida não encontrada')
		}
	}, [trip, user])

	const createTrip = useCallback(
		async (data: any, callback?: () => void) => {
			try {
				if (searching) {
					Map.hideSearch()
					cancelCurrentTrip()
					closeModal()
					searchEnd()
				} else {
					searchStart()
					setProvider(null)
					Map.searching()
					openModal()

					const response = await _createTrip(data)

					if (response && response.success) {
						setTrip(response.data)
						const { _id, user_id } = response.data
						const trip = await _checkTripStatus(_id, user_id)
						if (trip) {
							setProvider(trip)
							setTrip(null)
							if (callback) callback()
						}
					} else {
						toast.error('Nenhum entregador disponível, tente novamente...')
					}
				}
			} catch (err) {
				console.log(err)
				//@ts-ignore
				toast.error(err.message)
			}
		},
		[searching, provider, trip]
	)

	const [plannedTrips, setPlannedTrips] = useState<PlannedTrip[]>([])

	const getPlannedTrip = useCallback(
		async (costCenterId: string) => {
			if (!user || !costCenterId) return

			try {
				const response = await client.post<ResponseWithPlannedTrip>(
					`delivery/v1.1/trip/trip-plan`,
					{
						company_id: user.company,
						cost_center_id: costCenterId
					}
				)

				if (response.success) {
					setPlannedTrips(response.data)
				} else throw new Error('Nenhuma entrega encontrada.')
			} catch (err) {
				console.log(err)
				//@ts-ignore
				toast.warn(err.message)
			}
		},
		[user]
	)

	const cancelPlannedTrip = useCallback(
		(tripPlanId: string, costCenterId: string) => async () => {
			if (!user) return

			try {
				const response = await client.post<Response>(
					`delivery/v1.1/trip/trip-plan/${tripPlanId}/cancel`,
					{
						company_id: user.company,
						cost_center_id: costCenterId
					}
				)

				if (response.success) {
					setPlannedTrips(trips => trips.filter(trip => trip._id !== tripPlanId))
				} else throw new Error('Não foi possivel cancelar esta entrega')
			} catch (err) {
				console.log(err)
				//@ts-ignore
				toast.error(err.message)
			}
		},
		[]
	)

	const confirmPlannedTrip = useCallback(
		(tripPlanId: string, costCenterId: string, typeId: string) => async () => {
			if (!user) return

			try {
				Map.searching()
				searchStart()
				openModal()

				const response = await client.post<ResponseWithTrip>(
					`delivery/v1.1/trip/trip-plan/${tripPlanId}/confirm`,
					{
						company_id: user.company,
						cost_center_id: costCenterId,
						type_id: typeId
					}
				)

				if (response.success) {
					const { _id, user_id } = response.data
					const trip = await _checkTripStatus(_id, user_id)
					if (trip) {
						setProvider(trip)
						setPlannedTrips(plannedTrips =>
							plannedTrips.filter(plannedTrip => plannedTrip._id !== tripPlanId)
						)
					}
				} else {
					throw new Error('Error ao criar entrega.')
				}

				Map.hideSearch()
				searchEnd()
			} catch (err) {
				console.log(err)
				//@ts-ignore
				toast.error(err.message)
				closeModal()
			}
		},
		[]
	)

	const [schedulesTrips, setSchedule] = useState<ScheduleTrip[]>([])

	const makeScheduleTrip = useCallback(async (data: any, options: ScheduleOptionsCallback) => {
		try {
			const response = await client.post<Response>('delivery/v1.1/trip/trip-schedule-create', data)

			if (response.success) {
				toast.success('Agendamento criado com sucesso.')
				if (options.successCallback) options.successCallback()
			} else throw new Error('Falha ao criar agendamento.')
		} catch (err) {
			console.log(err)
			//@ts-ignore
			toast.error(err.message)
		} finally {
			if (options.endCallback) options.endCallback()
		}
	}, [])

	const getSchedules = useCallback(
		async (cost_center_id: string) => {
			if (!user) return

			try {
				loadStart()
				const response = await client.post<ResponseWithScheduleTrip>(
					'delivery/v1.1/trip/trip-schedule',
					{
						company_id: user.company,
						cost_center_id
					}
				)

				if (response.success) {
					setSchedule(response.data)
				}
			} catch (err) {
			} finally {
				loadEnd()
			}
		},
		[user]
	)

	const cancelScheduleTrip = useCallback(
		(tripScheduleId: string, costCenterId: string) => async () => {
			if (!user) return

			try {
				const response = await client.post<Response>(
					`delivery/v1.1/trip/trip-schedule/${tripScheduleId}/cancel`,
					{
						company_id: user.company,
						cost_center_id: costCenterId
					}
				)

				if (response.success) {
					setSchedule(trips => trips.filter(trip => trip._id !== tripScheduleId))
				} else throw new Error('Não foi possivel cancelar esta entrega')
			} catch (err) {
				console.log(err)
				//@ts-ignore
				toast.error(err.message)
			}
		},
		[user]
	)

	return (
		<TripContext.Provider
			value={{
				trip,
				provider,
				loading,
				searching,
				cancelling,
				isModalOpen,
				motoboyNotFound,
				plannedTrips,
				schedulesTrips,
				createTrip,
				cancelCurrentTrip,
				closeModal,
				getSchedules,
				getPlannedTrip,
				makeScheduleTrip,
				cancelPlannedTrip,
				confirmPlannedTrip,
				// confirmScheduleTrip
				cancelScheduleTrip,
				setMotoboyNotFound
			}}
			{...props}
		/>
	)
}
