import { IconType } from 'react-icons/lib/cjs'

export enum ConfirmationType {
	NONE = 'none',
	SIGNATURE = 'signature',
	QRCODE = 'qrcode'
}

export enum PaymentType {
	CARD = 'card',
	CASH = 'cash',
	FACE_CREDIT = 'face_credit',
	FACE_DEBIT = 'face_debit'
}

export interface FormValues {
	[key: string]: any
	coordinates: [number, number]
	address_complement: string
	confirmationType: ConfirmationType
	paymentType: PaymentType
	money_change: string
	price: string
	length: string
	width: string
	height: string
	destiny: string
	description: string
	name: string
	phone: string
}

export interface FormState {
	price: number | null
	distance: string | null
	distances: any[]
	cost_center: string
	cost_centers: any[]
	cost_center_id: string | null
	cost_center_location: number[] | null
	pack_type_id: string | null
	direct_provider_document: string
	direct_provider: any
	direct_provider_loading: boolean
	values: FormValues[]
}

export interface ConfirmationTypesOptions {
	label: string
	icon: IconType
	type: ConfirmationType | PaymentType
}
