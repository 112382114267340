import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { cx } from '../../../../lib/classnames'

import SEARCHING from '../../../../assets/searching.svg'
import { useTrip } from '../../../../context/trip'
import { withRouter } from 'react-router'

export const ProviderModal = withRouter(({ location: { pathname } }) => {
  const { searching, isModalOpen, provider, closeModal } = useTrip()

  const _open = Boolean(pathname === '/' && isModalOpen)

  if (searching) {
    return (
      <SearchContainer className={cx({ open: _open })}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
        >
          <circle cx="12" cy="2" r="0" fill="currentColor">
            <animate
              attributeName="r"
              begin="0"
              calcMode="spline"
              dur="1s"
              keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
              repeatCount="indefinite"
              values="0;2;0;0"
            />
          </circle>
          <circle
            cx="12"
            cy="2"
            r="0"
            fill="currentColor"
            transform="rotate(45 12 12)"
          >
            <animate
              attributeName="r"
              begin="0.125s"
              calcMode="spline"
              dur="1s"
              keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
              repeatCount="indefinite"
              values="0;2;0;0"
            />
          </circle>
          <circle
            cx="12"
            cy="2"
            r="0"
            fill="currentColor"
            transform="rotate(90 12 12)"
          >
            <animate
              attributeName="r"
              begin="0.25s"
              calcMode="spline"
              dur="1s"
              keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
              repeatCount="indefinite"
              values="0;2;0;0"
            />
          </circle>
          <circle
            cx="12"
            cy="2"
            r="0"
            fill="currentColor"
            transform="rotate(135 12 12)"
          >
            <animate
              attributeName="r"
              begin="0.375s"
              calcMode="spline"
              dur="1s"
              keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
              repeatCount="indefinite"
              values="0;2;0;0"
            />
          </circle>
          <circle
            cx="12"
            cy="2"
            r="0"
            fill="currentColor"
            transform="rotate(180 12 12)"
          >
            <animate
              attributeName="r"
              begin="0.5s"
              calcMode="spline"
              dur="1s"
              keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
              repeatCount="indefinite"
              values="0;2;0;0"
            />
          </circle>
          <circle
            cx="12"
            cy="2"
            r="0"
            fill="currentColor"
            transform="rotate(225 12 12)"
          >
            <animate
              attributeName="r"
              begin="0.625s"
              calcMode="spline"
              dur="1s"
              keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
              repeatCount="indefinite"
              values="0;2;0;0"
            />
          </circle>
          <circle
            cx="12"
            cy="2"
            r="0"
            fill="currentColor"
            transform="rotate(270 12 12)"
          >
            <animate
              attributeName="r"
              begin="0.75s"
              calcMode="spline"
              dur="1s"
              keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
              repeatCount="indefinite"
              values="0;2;0;0"
            />
          </circle>
          <circle
            cx="12"
            cy="2"
            r="0"
            fill="currentColor"
            transform="rotate(315 12 12)"
          >
            <animate
              attributeName="r"
              begin="0.875s"
              calcMode="spline"
              dur="1s"
              keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
              repeatCount="indefinite"
              values="0;2;0;0"
            />
          </circle>
        </svg>
        <h1 style={{ textAlign: `center` }}>
          Aguarde um momento, estamos buscando entregador...
          {/* 
		  <br />
		  Caso não existam entregadores disponíveis, iremos direcionar um entregador para você. */}
        </h1>
      </SearchContainer>
    )
  }

  return provider ? (
    <ProviderContainer className={cx({ open: _open })}>
      <h1 className="title is-4">
        Tudo pronto, seu entregador está a caminho!
      </h1>
      <Content>
        <div>
          <ProviderPicture
            style={{ backgroundImage: `url(${provider.providerPicture})` }}
          />
        </div>
        <ProviderInfo>
          <h2>{provider.providerName}</h2>
          <p>{provider.providerCarModel}</p>
          <p>{provider.providerPhone}</p>
          <WhatsAppButton
            onClick={() =>
              window.open('https://wa.me/55' + provider.providerPhone, '_blank')
            }
          >
            Falar no Whatsapp
          </WhatsAppButton>
        </ProviderInfo>
      </Content>

      <CloseButton onClick={closeModal}>Fechar</CloseButton>
    </ProviderContainer>
  ) : null
})

const baseContainer = css`
  background: white;
  position: fixed;
  z-index: 1;
  padding: 1rem;
  bottom: 0;
  right: 0;
  left: 0;

  transform: translateX(-100%);
  transition: transform 300ms ease;

  &.open {
    transform: translateX(0);
  }
`

const ProviderContainer = styled.div`
  ${baseContainer};
`

const SearchContainer = styled.div`
  ${baseContainer};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  & img {
    background-size: contain;
    height: 120px;
  }
`

const Content = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: min-content 1fr;
`

const ProviderPicture = styled.div`
  width: 100px;
  height: 100px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;
`

const ProviderInfo = styled.div`
  & h2 {
    font-size: 1.4rem;
    font-weight: 600;
    color: #9e9e9e;
  }

  & p {
    font-size: 1.2rem;
  }
`

const CloseButton = styled.button`
  position: absolute;
  bottom: 1rem;
  right: 1rem;

  appearance: none;
  outline: none;
  border: 2px solid #9e9e9e;
  background: white;
  padding: 1rem 2rem;
  font-size: 1.2rem;
  font-weight: 600;
  color: #9e9e9e;
  cursor: pointer;
  transition: all 200ms ease;

  &:hover {
    background: #9e9e9e;
    color: #fff;
  }
`

const WhatsAppButton = styled.button`
  appearance: none;
  outline: none;
  padding: 1rem 2rem;
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--fooy);
  cursor: pointer;
  transition: all 200ms ease;

  &:hover {
    background: var(--fooy);
    color: #ccc;
  }
`

