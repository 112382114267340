import { useEffect, useRef, useState, useCallback } from 'react'
import {
	ResponseTrackProvider,
	Response,
	ResponseWithProviderTrip
} from '../../context/responses.type'
import { client } from '../../context/Client'
import Map from '../../lib/Map'
import { useToggle } from '../../lib/hooks'
import { Trip } from '../../context/history/history.type'
import { displayTrip } from '../../container/History/HistoryItem'

export function useTrackState(tripId: string) {
	const timeout = useRef<any>(0)

	useEffect(() => {
		const getNewPosition = request(tripId)

		async function updatePosition(count?: number) {
			try {
				const data = await getNewPosition(count)

				if (!data) {
					timeout.current = setTimeout(() => updatePosition(count), 10000)
					return
				}

				const { index, provider_location, route } = data

				if (Array.isArray(provider_location) && provider_location.length > 0) {
					const [lat, lng] = provider_location
					Map.updateProviderPosition({ lat, lng })
				}

				if (Array.isArray(route) && route.length > 0) {
					Map.updateProviderRoute(route.map(point => ({ lat: point[0], lng: point[1] })))
				}

				timeout.current = setTimeout(() => updatePosition(index), 10000)
			} catch (err) {
				console.log(err)
			}
		}

		timeout.current = setTimeout(() => updatePosition(0), 0)

		return () => {
			clearTimeout(timeout.current)
		}
	}, [])
}

interface Provider {
	first_name: string
	last_name: string
	picture: string
	phone: string
}

export function useTripInfo(tripId: string) {
	const [_trip, settrip] = useState<Trip | null>(null)

	const [_provider, setProvider] = useState<Provider | null>(null)

	const { active: loading, enabled: startLoading, disabled: endLoading } = useToggle(false)

	const bootStrapData = useCallback(async () => {
		try {
			startLoading()
			const response = await client.get<ResponseWithProviderTrip>(`delivery/v1.1/trip/${tripId}`)
			const { provider, trip } = response.data

			if (Array.isArray(trip.destinations)) {
				displayTrip(trip.destinations)
			}

			settrip(trip)
			setProvider(provider)
			endLoading()
		} catch (err) {
			console.log(err)
			endLoading()
		}
	}, [])

	useEffect(() => {
		bootStrapData()
	}, [])

	return {
		provider: _provider,
		trip: _trip,
		loading
	}
}

function request(tripId: string) {
	return async (index?: number) => {
		try {
			const response = await client.get<ResponseTrackProvider>(
				`delivery/v1.1/trip/${tripId}/track/${index || 0}`
			)
			if (response.success) {
				return response.data
			}

			throw new Error('Falha na requisição')
		} catch (err) {
			console.log(err)
			return null
		}
	}
}
