import React, { useState, useCallback } from 'react'
import { TriggerProp } from '../ModalConfirm'
import {  Container, Header, CloseButton, CancelButton } from '../ModalConfirm/styles'
import { useToggle } from '../../../lib/hooks'
import { Modal } from '../../../components/PlaceInput/Modal'
import { FaTimesCircle } from 'react-icons/fa'
import { Content, Footer, Confirm } from '../../Order/Components/ScheduleModal/styles'
import { Rating } from '../Rating/Rating'

interface Props {
	action(rate: number, callback: () => void): void
	trigger(action: TriggerProp): any
}

export const ModalRating: React.SFC<Props> = ({ trigger, action }) => {
	const { active, enabled, disabled } = useToggle(false)

	const [rating, setRating] = useState<number | null>(null)

	const disabledConfirm = !Boolean(rating)

	const submitRating = useCallback(() => {
		if (!!rating) action(rating, disabled)
	}, [rating, action])

	if (active) {
		return (
			<React.Fragment>
				{trigger({ action: disabled })}
				<Modal close={disabled}>
					<Container>
						<Header>
							<h1>Avalie o entregador</h1>
							<CloseButton onClick={disabled}>
								<FaTimesCircle />
							</CloseButton>
						</Header>
						<Content>
							<Rating updateCallback={setRating} />
						</Content>
						<Footer>
							<CancelButton onClick={disabled}>Cancelar</CancelButton>
							<Confirm disabled={disabledConfirm} onClick={submitRating}>
								Confirmar
							</Confirm>
						</Footer>
					</Container>
				</Modal>
			</React.Fragment>
		)
	}

	return trigger({ action: enabled })
}
