import React, { useContext } from 'react'
import { login, logout } from './authClient'
import { User } from './auth.schema'
import { client } from '../Client'

import { ResponseWithCostCenter, CostCenter } from '../responses.type'

interface IContext {
	state: State
	logout(): void
	setUser(user: User): void
	addToQueue(func: any): void
	login(email: string, pass: string): void
}

interface State {
	user: User | null
	costCenters: CostCenter[]
	isLogin: boolean
	loading: boolean
}

export const AuthContext = React.createContext({} as IContext)

export function useAuth() {
	const context = useContext(AuthContext)
	if (context === undefined) {
		throw new Error('useAuth can only be used within <AuthProvider />')
	}
	return context
}

export class AuthProvider extends React.PureComponent<{}, State> {
	private _queue: any[] = []

	state = {
		user: null,
		costCenters: [],
		isLogin: false,
		loading: false
	} as State

	addToQueue = (func: any): void => {
		this._queue.push(func)
	}

	_runQueue = () => {
		this._queue.forEach(func => func())
		this._queue = []
	}

	setUser = (user: User) => {
		this.setState({ user, loading: false }, this.getCostCenter)
	}

	signin = (email: string, password: string) => {
		this.setState({ loading: true }, async () => {
			try {
				const response = await login({
					email,
					password
				})

				if (response.success) {
					this.setState(
						{
							user: response.data.user,
							loading: false
						},
						this.getCostCenter
					)
				} else this.logout()
			} catch (err) {
				this.logout()
				// ts-ignore
				throw new Error(err.message as any)
			}
		})
	}

	logout = () => {
		logout().then(() => {
			this.setState({
				user: null,
				loading: false
			})
		})
	}

	getCostCenter = async (): Promise<void> => {
		const { company } = this.state.user!

		const costCenterURL = `api/company/v1/companies/${company}/cost-centers`
		const response: ResponseWithCostCenter = await client.get<ResponseWithCostCenter>(
			costCenterURL
		)

		if (response.success) {
			this.setState({
				costCenters: response.data
			})
		}
	}

	render() {
		const value = {
			state: { ...this.state },
			login: this.signin,
			logout: this.logout,
			setUser: this.setUser,
			addToQueue: this.addToQueue
		}

		return <AuthContext.Provider value={value} {...this.props} />
	}
}
