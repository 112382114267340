import styled from 'styled-components'

export const Container = styled.div`
	display: grid;
	grid-template-columns: 1.5fr 1fr;
	min-height: 100vh;

	@media screen and (max-width: 768px) {
		grid-template-columns: 1fr;
	}
`

export const Form = styled.form`
	display: flex;
	flex-direction: column;
	background: var(--fooy);
	border-radius: 2px;
	padding: 30px 35px 200px;
`

export const Banner = styled.div`
	display: flex;
	align-items: center;

	& > img {
		width: 60rem;
	}

	@media screen and (max-width: 768px) {
		display: none;
	}
`

export const FormContent = styled.div`
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;

	h1 {
		color: var(--fooy-dark);
	}
`

export const ImageContainer = styled.div`
	position: relative;
	padding: 10px;
	display: flex;
	flex-direction: column;
	align-items: center;

	& > img {
		max-width: 220px;
	}

	p {
		color: #fff;
	}
`

export const Button = styled.button`
	appearance: none;
	border: none;
	background: var(--fooy-dark);
	margin-top: 2rem;
	width: 50%;
	font-size: 1rem;
	font-weight: 600;
	color: #fff;
	box-shadow: 0 1px 6px 1px rgba(0, 0, 0, 0.3);
	height: 44px;
	border-radius: 20px;
	cursor: pointer;

	&:hover {
		opacity: 0.9;
	}

	
`
