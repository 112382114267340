import React, { useRef, useEffect } from 'react'
import styled from '@emotion/styled'
import Map from '../../../../lib/Map'

interface Props {
	mapOptions?: google.maps.MapOptions
}

export const MapContainer = React.memo(({ mapOptions }: Props) => {
	const map = useRef<HTMLDivElement>(null)

	useEffect(() => {
		console.log(mapOptions)
		if (map.current) Map.init(map.current, mapOptions)
	}, [])

	return <Container ref={map} />
})

const Container = styled.div`
	position: relative;
	display: flex;
	height: 100%;
	flex: 1;
`
