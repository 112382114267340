import styled, {css} from 'styled-components'

export const position = css`
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
`

export const Container = styled.div`
	z-index: 99;
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	${position}
`

export const Background = styled.div`
	background: rgba(0, 0, 0, 0.8);
	position: absolute;
	${position}
`
