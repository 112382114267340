import React, { useCallback, useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router'

import { client } from '../../../context/Client'
import { Response } from '../../../context/responses.type'

import LOGO from '../../../assets/logo_white.png'
import QRCodePix from '../../../assets/pix.png'

import { FaAt, FaKey } from 'react-icons/fa'
import styled from '@emotion/styled'
import { Loading } from '../../../components/Loading'
import {
	IoMdPower,
	IoIosStats,
	IoIosNotifications,
	IoMdClose,
	IoMdCash,
	IoMdLink,
	IoMdPhoneLandscape,
	IoMdContact,
	IoMdDoneAll,
	IoMdBarcode,
	IoIosArchive,
	IoIosFolder,
	IoMdCopy,
	IoMdQrScanner,
	IoMdPrint
} from 'react-icons/io'
import copy from 'copy-to-clipboard';
import { toast } from 'react-toastify'
import { ContainerInvoice } from './styles'


const today = new Date();
const currentday = today.toString().substring(0, 10);

interface Prop extends RouteComponentProps<{ invoice_id: string }> { };

export default ({ match: { params: { invoice_id } } }: Prop) => {
	const [invoice, setInvoiceDetail] = useState({
		_id: "",
		type: "",
		payday: "",
		description: "",
		done: false,
		value: 0,
		category: "",
		city: "",
		company: "",
		provider: null,
		due_date: "",
		attachment: {
			emv_payload: ""
		},
		attachment_value: "",
		trips: [{
			created_at: '',
			total_for_company: 0,
			provider_first_name: '',
			provider_last_name: '',
			company_cost_center_id: '',
			destinations: [{
				address: '',
				distance: 0
			}]
		}],
		fromCompany: [{
			document: '',
			name: '',
			full_name: '',
			platform_manager: '',
			financial_phone: ''
		}],
		fromCostCenter: [{
			_id: '',
			name: '',
			address: ''
		}]
	});

	const fetchInvoiceDetail = (refresh = false) => {
		try {
			client.get<Response>(`admin/v1/control-financial/register/invoices/find_complete/${invoice_id}`).then(response => {
				if (response.success) {
					setInvoiceDetail(response.data[0]);
					console.log(response.data);
				} else throw new Error('Não foi possivel carregar as faturas')
			})
		} catch (err) {
			console.log(err)
		}
	}

	const copyToClipboard = async () => {
		var text = invoice.attachment.emv_payload;
		await copy(text);
		toast.success("Código PIX Copiado", { position: 'top-right' });
	}

	useEffect(() => {
		fetchInvoiceDetail();
	}, [])

	return (invoice.value > 0 ?
		<ContainerInvoice>
			<div style={{ backgroundColor: "#11cf99", padding: '15px 30px', marginBottom: 30 }}>
				<div className={'row'} style={{ margin: "0 auto", maxWidth: "900px" }}>
					<div className={"col-6"}> <img src={"https://api-temp.vercel.app/vaay/logo_white.png"} style={{ maxWidth: 100 }} /></div>
					<div className={"col-6"} style={{ color: "#FFF", textAlign: 'right' }}>
						<button onClick={() => window.print()} style={{ cursor: "pointer", backgroundColor: "#FFF", color: "#111", padding: "8px 12px", borderRadius: 100, border: "solid 1px #111", marginTop: 15, whiteSpace: 'nowrap' }}> <IoMdPrint style={{ marginBottom: -2 }} /> SALVAR / IMPRIMIR</button>
					</div>
				</div>
			</div>
			<div>
				<div className={'row'} style={{ margin: "0 auto", padding: 20, borderRadius: 12, maxWidth: "900px" }}>
					<div className={"col-sm-6"} style={{ textAlign: 'left', paddingRight: '10px', marginBottom: 30 }}>
						<div style={{ marginBottom: 10, paddingBottom: 10, borderBottom: 'solid 1px #EEE', fontSize: 16, fontWeight: 'bold' }}>FATURA EM NOME DE</div>
						<div style={{ marginBottom: 10 }}>
							<div>{invoice.fromCompany[0].name}</div>
							<div style={{ fontSize: 11 }}>RAZÃO: {invoice.fromCompany[0].full_name}</div>
							<div style={{ fontSize: 11 }}>DOCUMENTO: {invoice.fromCompany[0].document}</div>
						</div>
						<div>
							<div style={{ fontSize: 11 }}>RESPONSÁVEL: {invoice.fromCompany[0].platform_manager}</div>
							<div style={{ fontSize: 11 }}>CONTATO: {invoice.fromCompany[0].financial_phone}</div>
						</div>
					</div>
					<div className={"col-sm-6"} style={{ textAlign: 'left', paddingRight: '10px', marginBottom: 30 }}>
						<div style={{ marginBottom: 10, paddingBottom: 10, borderBottom: 'solid 1px #EEE', fontSize: 16, fontWeight: 'bold' }}>EMITIDA E REGISTRADA POR</div>
						<div style={{ marginBottom: 10 }}>
							<div>VAAY ENTREGAS</div>
							<div style={{ fontSize: 11 }}>RAZÃO: TFD SERVICOS DE INTERMEDIACAO LTDA</div>
							<div style={{ fontSize: 11 }}>DOCUMENTO: 32.236.865/0001-48</div>
						</div>
					</div>
				</div>
				<div className={'row'} style={{ margin: "0 auto", backgroundColor: "#F5F5F5", padding: 20, borderRadius: 12, maxWidth: "900px" }}>
					<div className={"col-sm-8"} style={{ textAlign: 'left', padding: '20px 10px' }}>
						<div style={{ fontSize: 36, fontWeight: 'bold' }}>RESUMO DA FATURA</div>
						<div style={{ marginBottom: 15, fontSize: 14 }}>{invoice.description}</div>
						<div style={{ marginBottom: 15, fontSize: 14 }}>
							<div style={{ fontSize: 11 }}>DATA DE VENCIMENTO</div>
							{new Date(invoice.due_date).toLocaleDateString()}&nbsp;
							<small style={{ fontSize: 11 }}>{
								(invoice.done ? <span style={{ background: "#11cf99", padding: '4px 8px', color: '#FFF', borderRadius: 6 }}>PAGO</span>
									: (today > new Date(invoice.due_date!) ? <span style={{ background: "#ff3860", padding: '4px 8px', color: '#FFF', borderRadius: 6 }}>VENCIDA</span>
										: <span style={{ background: "#f4b835", padding: '4px 8px', color: '#FFF', borderRadius: 6 }}> PENDENTE</span>)
								)}
							</small>
						</div>
						<div style={{ fontSize: 11 }}>TOTAL A PAGAR</div>
						<div style={{ fontSize: 36 }}><b>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(invoice.value)}</b></div>
						<div style={{ fontSize: 14 }}><b><i>Chave Pix para pagamento: 32.236.865/0001-48 - TFD SERVIÇOS DE INTERMEDIAÇÃO</i></b></div>

					</div>
					<div className={"col-sm-4"} style={{ textAlign: 'center' }}>
						{/* <div style={{fontSize:15}}>Chave Pix para pagamento: 32.236.865/0001-48 - TFD SERVIÇOS DE INTERMEDIAÇÃO</div> */}
						<img src={invoice.attachment_value.length > 200 ? `data:image/png;base64,${invoice.attachment_value}` : QRCodePix} style={{width:"100%", maxWidth:180, padding:10, borderRadius:6, backgroundColor:"#FFF", marginTop:15, marginBottom:5}}/> 
						<div><button onClick={() => copyToClipboard()} style={{cursor:"pointer",backgroundColor:"#111", color:"#FFF", padding:"8px 12px", borderRadius:100, border:"none"}}> <IoMdCopy style={{marginBottom:-2}}/> Copiar Código PIX</button></div> 
					</div>
				</div>
				<div className={'row'} style={{ margin: "0 auto", maxWidth: "900px", marginTop: 30 }}>
					<div style={{ fontSize: 16, fontWeight: 'bold', padding: 20 }}>EXTRATO DE ENTREGAS</div>
					<table style={{ width: "100%", margin: "0 auto", marginBottom: 0 }}>
						<thead style={{ backgroundColor: "#EEE", borderBottom: "solid 2px #AAA" }}>
							<tr>
								<th style={{ padding: "3px 6px" }}>Data/Hora</th>
								<th style={{ padding: "3px 6px" }}>Entregador</th>
								<th style={{ padding: "3px 6px" }}>Destino</th>
								<th style={{ padding: "3px 6px" }}>Valor</th>
							</tr>
						</thead>
						<tbody style={{ fontSize: 14 }}>
							{invoice.fromCostCenter.map(costcenter => (
								(invoice.trips.filter(t => t.company_cost_center_id == costcenter._id).length ?
									invoice.trips.filter(t => t.company_cost_center_id == costcenter._id).map((trip, index) => (
										<>
											<>{index == 0 ?
												(<tr><td colSpan={4} style={{ padding: "6px 6px", paddingTop: '24px', borderBottom: "solid 2px #AAA", textAlign: 'left' }}><b>{costcenter.name}</b> - {costcenter.address}</td></tr>)
												: null}</>
											<tr>
												<td style={{ padding: "3px 6px", borderBottom: "solid 1px #EEE" }}>
													{new Date(trip.created_at).toLocaleString("pt-BR").substring(0, 16)}
												</td>
												<td style={{ padding: "3px 6px", borderBottom: "solid 1px #EEE" }}>{trip.provider_first_name} {trip.provider_last_name}</td>
												<td style={{ padding: "3px 6px", borderBottom: "solid 1px #EEE" }}>{
													trip.destinations.map((d, i) => <div style={{ marginBottom: 5, fontSize: 11 }}>{i + 1} - {d.address} - ({d.distance}km)</div>)
												}</td>
												<td style={{ padding: "3px 6px", borderBottom: "solid 1px #EEE" }}>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(trip.total_for_company)}</td>
											</tr>
										</>
									))
									: null)
							))}
						</tbody>
					</table>
				</div>
			</div>
		</ContainerInvoice>
		: null);
}