import React from 'react'
import { useToggle, useStopPropagation } from '../../../lib/hooks'
import { FaTimesCircle } from 'react-icons/fa'

import { Modal } from '../../../components/PlaceInput/Modal'
import { CancelButton, CloseButton, ConfirmButton, Container, Content, Header } from './styles'

export interface TriggerProp {
	action?: () => void
}

interface Props {
	title: string
	trigger(action: TriggerProp): any
	action(): void
}

export const ModalConfirm = ({ trigger, action, title }: Props) => {
	const { active, toggle, disabled } = useToggle(false)

	const _action = useStopPropagation(action)
	const close = useStopPropagation(disabled)

	if (active) {
		return (
			<React.Fragment>
				{trigger({ action: toggle })}
				<Modal close={close}>
					<Container>
						<Header>
							<h1>{title}</h1>
							<CloseButton onClick={disabled}>
								<FaTimesCircle />
							</CloseButton>
						</Header>
						<Content>
							<CancelButton onClick={disabled}>NÃO</CancelButton>
							<ConfirmButton onClick={_action}>SIM</ConfirmButton>
						</Content>
					</Container>
				</Modal>
			</React.Fragment>
		)
	}

	return trigger({ action: toggle })
}
