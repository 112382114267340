/*global google*/

import React, { useContext } from 'react'
//@ts-ignore
import debounce from 'lodash.debounce'
import Map from '../../lib/Map'

interface IContext {
	state: State
	getSuggestion(suggestion: Suggestion): string
	getSuggestions(target: { value: string; reason: string }): void
	clearSuggestions(): void
}

export const PlaceContext = React.createContext({} as IContext)

export function usePlaceSuggestion() {
	const context = useContext(PlaceContext)
	if (context == undefined) {
		throw new Error('usePlaceSuggestion can only be used within <PlaceSuggestionProvider />')
	}

	return context
}

interface Props {
	defaultValue?: string
}

export interface Suggestion {
	description: string
	id: string
}

interface State {
	ready: boolean
	value: string
	loading: boolean
	suggestions: Suggestion[]
	bounds: google.maps.LatLngBounds | null
}

export class PlaceSuggestionProvider extends React.PureComponent<Props, State> {
	debouncePrediction: any = null
	autocompleteService = {} as google.maps.places.AutocompleteService
	autocompleteOK: any = ''

	constructor(props: any) {
		super(props)

		this.state = {
			value: this.props.defaultValue || '',
			ready: false,
			loading: false,
			bounds: null,
			suggestions: []
		} as State

		this.debouncePrediction = debounce(this.getPredictions, 600)
	}

	changeCallback = () => {
		const { value } = this.state
		if (!value) return this.clearSuggestions()
	}

	clearSuggestions = () => {
		this.setState({ suggestions: [] })
	}

	componentDidMount() {
		if (Map.ready) this.init()
		else Map.addToQueue(this.init)
	}

	init = () => {
		this.autocompleteService = new google.maps.places.AutocompleteService()
		this.autocompleteOK = google.maps.places.PlacesServiceStatus.OK
		this.setState(state => (state.ready ? null : { ready: true }))
	}

	getPrediction = (suggestion: Suggestion) => {
		return suggestion.description
	}

	getPredictions = async (target: { value: string; reason: string }) => {
		const { value, reason } = target
		if (value && reason === 'input-changed') {
			this.setState({ loading: true }, () => {
				try {
					this.autocompleteService.getPlacePredictions(
						{
							input: value,
							radius: 100000,
							location: Map.location!.getPosition(),
							componentRestrictions: { country: 'br' }
						},
						this.autocompleteCallback
					)
				} catch (err) {
					console.log(err)
					this.setState({ loading: false })
				}
			})
		}
	}

	autocompleteCallback = (
		predictions: google.maps.places.AutocompletePrediction[],
		status: google.maps.places.PlacesServiceStatus
	) => {
		this.setState({ loading: false }, () => {
			if (status !== this.autocompleteOK) this.clearSuggestions()
			else
				this.setState({
					suggestions: predictions.map(p => ({
						id: p.place_id,
						description: p.description
					}))
				})
		})
	}

	render() {
		const value = {
			state: { ...this.state },
			// onchange: this.handleInputChange,
			getSuggestion: this.getPrediction,
			getSuggestions: this.debouncePrediction,
			clearSuggestions: this.clearSuggestions
		}

		return <PlaceContext.Provider value={value}>{this.props.children}</PlaceContext.Provider>
	}
}
