import React, { useContext, useState, useCallback, useEffect } from 'react'
import { client } from '../Client'
import { useAuth } from '../auth'
// import produce from 'immer'

import { Trip, ITrip } from './history.type'
import { format } from 'date-fns'
import { Response, ResponseWithHistory } from '../responses.type'
import { useToggle } from '../../lib/hooks'
import { toast } from 'react-toastify'
import { displayNotification } from '../../lib/notification'

interface State {
	loading: boolean
	historys: ITrip[]
}

interface Context extends State {
	clear(): void
	cancelTrip(tripId: string): void
	finishTrip(tripId: string, rating: number, cb: () => void): void
	loadHistory(): void
}

export const HistoryContext = React.createContext({} as Context)

export function useHistoryContext() {
	const context = useContext(HistoryContext)

	if (context === undefined) {
		throw new Error('HistoryContext can only be used within <HistotyProvider />')
	}
	return context
}

export const HistoryProvider: React.SFC = props => {
	const {
		state: { user }
	} = useAuth()

	const { active: loading, enabled: loadingStart, disabled: loadingEnd } = useToggle(false)

	const [historys, setHistory] = useState<ITrip[]>([])

	// useEffect(() => {
	// 	let timeout: any = 0

	// 	if (user) {
	// 		const url = `delivery/v1.1/trip/company/${user!.company}/active`

	// 		async function checkHistoryStatus() {
	// 			const response = await client.get<ResponseWithHistory>(url)
	// 			if (response.success) {
	// 				const _trips = parseTripResponse(response.data.trips)
	// 				setHistory(previous => {
	// 					diffTrips(previous, _trips)
	// 					return _trips
	// 				})
	// 			}

	// 			setTimeout(checkHistoryStatus, 30000)
	// 		}

	// 		checkHistoryStatus()
	// 	}

	// 	return () => {
	// 		clearTimeout(timeout)
	// 	}
	// }, [user])

	const loadHistory = useCallback(async () => {
		if (!user) return

		try {
			loadingStart()
			const url = `delivery/v1.1/trip/company/${user!.company}/active`
			const response = await client.get<ResponseWithHistory>(url)

			if (response.success) {
				setHistory(parseTripResponse(response.data.trips))
			}
		} catch (err) {
			console.log(err)
		} finally {
			loadingEnd()
		}
	}, [user])

	const finishTrip = useCallback(
		async (tripId: string, rating: number) => {
			if (!user) return

			try {
				loadingStart()
				const url = `delivery/v1.1/trip/${tripId}/conclude`
				const response = await client.post<Response>(url, {
					rating
				})

				if (response.success) {
					toast.success('Entrega finalizada')
					loadingEnd()
				} else {
					toast.error(response.error.message)
					loadingEnd()
				}
			} catch (err) {
				loadingEnd()
				console.log(err)
			}
		},
		[user]
	)

	const cancelTrip = useCallback(
		async (tripId: string) => {
			if (!user) return

			try {
				loadingStart()
				const url = `delivery/v1.1/trip/${tripId}/cancel`
				const response = await client.post<Response>(url)

				if (response.success) {
					toast.success(`Entrega cancelada.`)
					setHistory(history => history.filter(h => h._id !== tripId))
					loadingEnd()
				} else {
					if (response && response.error && response.error.message) {
						toast.success(response.error.message)
					}
				}
			} catch (err) {
				loadingEnd()
				console.log(err)
			}
		},
		[user]
	)

	// const rateProvider = useCallback(async (tripId: string, rate: number, callback?: () => void) => {
	// 	try {
	// 		const response = await client.post<Response>(
	// 			`delivery/v1.1/trip/${tripId}/rating`,
	// 			{
	// 				rate
	// 			}
	// 		)

	// 		if (response.success) {
	// 			if (callback) callback()
	// 		} else throw new Error('Falha ao avaliar entregador.\nTente Novamente mais tarde.')
	// 	} catch (err) {
	// 		console.log(err)
	// 		toast.error(err.message)
	// 	}
	// }, [])

	const clear = useCallback(() => setHistory([]), [])

	return (
		<HistoryContext.Provider
			value={{
				clear,
				loading,
				historys,
				// rateProvider,
				finishTrip,
				cancelTrip,
				loadHistory
			}}
			{...props}
		/>
	)
}

export function parseTripResponse(trips: Trip[]): ITrip[] {
	return trips.map(trip => parseTrip(trip))
}

export function parseTrip(trip: Trip): ITrip {
	let provider = trip.provider
	return {
		_id: trip._id,
		unique_id: `#${trip.unique_id}`,
		address: trip.source_address,
		tripDate: format(new Date(trip.created_at), 'DD/MM/YYYY HH:mm'),
		providerCarModel: `${provider.car_model} ${provider.car_number}`,
		providerName: `${provider.first_name} ${provider.last_name}`,
		providerPicture: `https://api.vaay.app/storage/${provider.picture}`,
		providerPhone: provider.phone,
		tripPrice: trip.total.toString(),
    tripStatus: trip.status,
    is_delivery_completed: trip.is_delivery_completed,
		destinations: trip.destinations,
		type_name: trip.type_name
	}
}

// function diffTrips(oldTrips: ITrip[], newTrips: ITrip[]) {
// 	if (oldTrips.length && newTrips.length) return

// 	let changesCount = 0

// 	newTrips.forEach(trip => {
// 		const oldTrip = oldTrips.find(o => o._id === trip._id)

// 		if (oldTrip) {
// 			if (oldTrip.tripStatus !== trip.tripStatus) {
// 				changesCount++
// 			}
// 		}
// 	})

// 	if (changesCount > 0) {
// 		displayNotification('Status Alterado', 'Houve alterações em seus pedidos')
// 	}
// }
