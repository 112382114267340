import { ProviderInfo, TripProviderInfo } from './tripProvider.type'
import { client } from '../Client'
import { ResponseWithProvider, ResponseWithTrip } from '../responses.type'

export async function _createTrip(data: any): Promise<ResponseWithTrip | null> {
	try {
		const response = await client.post<ResponseWithTrip>(`delivery/v1.1/trip`, data)

		return response
	} catch (err) {
		console.log(err)
		return null
	}
}

// export function checkTripStatus(trip_id: string, user_id: string): Promise<ProviderInfo | null> {
// 	return new Promise(resolve => {
// 		const started = Date.now()

// 		const checkStatus = async () => {
// 			let duration = Date.now() - started

// 			if (duration > 120000) resolve(null)

// 			const response = await client.get<ResponseWithProvider>(
// 				`api/company/v1/users/${user_id}/trip/${trip_id}/status`
// 			)

// 			if (!response.success) {
// 				resolve(null)
// 			}

// 			const { is_provider_accepted, is_trip_cancelled, provider } = response.data

// 			if (is_provider_accepted === 2) {
// 				setTimeout(checkStatus, 2000)
// 			} else if (is_provider_accepted === 1 && provider) {
// 				resolve(parseProvider(response.data))
// 			} else if (is_trip_cancelled === 1) {
// 				resolve(null)
// 			} else {
// 				console.log(response)
// 			}
// 		}

// 		checkStatus()
// 	})
// }

export function parseProvider(trip: TripProviderInfo): ProviderInfo {
	let provider = trip.provider
	return {
		_id: trip._id,
		providerCarModel: `${provider.car_model} ${provider.car_number}`,
		providerName: `${provider.first_name} ${provider.last_name}`,
		providerPicture: `https://api.vaay.app/storage/${provider.picture}`,
		providerPhone: provider.phone
	}
}
