import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	align-items: center;
	width: 165px;
	height: 100%;
`

export const Label = styled.label`
	flex: 1;
	height: 100%;
	background: #757575;
	font-size: 1.5rem;
	cursor: pointer;
	color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	border-radius: 4px;

	&:hover {
		opacity: 0.9;
	}

	& span {
		opacity: 0;
		transition: all 300ms ease;
		position: absolute;
		padding: 0.4rem;
		color: #fff;
		background: #757575;
		pointer-events: none;
		top: 0;
		left: 0;
	}

	&:hover span {
		opacity: 1;
		transform: translateY(-105%);
	}

	margin-right: 0.1rem;
	&:last-child {
		margin-right: 0rem;
	}
`

export const Input = styled.input`
	&:checked + label {
		background: var(--fooy);
	}
`