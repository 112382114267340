import { getUser, getToken, logout } from './authClient'
import { useAuth } from '.'

export const useLoginState = (): boolean => {
	const token = getToken()

	const {
		state: { user },
		setUser
	} = useAuth()

	if (user) return true
	if (!token) return false

	const promise = getUser()

	promise.then(res => {
		if (res && res.success) setUser(res.data)
		else logout()
	})

	throw promise
}
