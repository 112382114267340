import styled from 'styled-components'

export const DestinationContainer = styled.div`
	flex: 1 1;
	justify-content: center;
	align-content: center;
	grid-gap: 1rem;
	grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
	grid-auto-rows: min-content;
	max-width: 100%;

	&::-webkit-scrollbar {
		width: 6px;
	}

	&::-webkit-scrollbar-thumb {
		background: rgba(68, 87, 187, 0.4);
		border-radius: 10px;
		opacity: 0;
	}

	&::-webkit-scrollbar-thumb:hover {
		background: #4457bb;
	}
`

export const RemoveButton = styled.button`
	appearance: none;
	position: absolute;
	top: 0;
	right: 0;
	padding: 0;
	margin: 4px;
	width: 1rem;
	height: 1rem;
	border: none;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	opacity: 0;
	/* z-index: 1; */

	transition: 0.2s;

	&:active,
	&:hover {
		background: #e53935;
		color: #fff;
	}
`

export const Destination = styled.div`
	position: relative;
	border-radius: 6px;
	margin-bottom: 0.6rem;
	padding: 20px;
	background-color: var(--fooy-sec-background);
	display: block;

	&:hover ${RemoveButton} {
		opacity: 1;
	}
`
export const ButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items:center;
`

export const AddButton = styled.button`
	padding: 0.9rem;
	margin-bottom: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
	border-radius: 50%;
	background-color: var(--fooy);
	font-size: 1.2rem;
	font-weight: 600;
	color: white;
	cursor: pointer;
	transition: transform 100ms ease;
	outline: none;
	--justify-self: end;
	/* width: 100%; */

	&:hover {
		opacity: 0.9;
	}

	&:disabled {
		pointer-events: none;
		background: #757575;
	}

	&:active {
		transform: scale(0.9);
	}
`

export const OneContainer = styled.div`
	display: grid;
	grid-gap: 1rem;
	margin: 1rem 0;
	grid-template-columns: 1fr;
`
export const OneContainerDisabled = styled.div`
	display: none;
	grid-gap: 1rem;
	margin: 1rem 0;
	grid-template-columns: 1fr;
`

export const DoubleContainer = styled.div`
	display: grid;
	grid-gap: 1rem;
	margin: 1rem 0;
	grid-template-columns: 1fr 1fr;
	transition: all 300ms linear;

	&.hidden {
		height: 0;
		margin: 0;
		transform: scaleY(0);
	}
`

export const ProductInfo = styled.div`
	display: none;
	grid-gap: 1rem;
	margin-bottom: 1rem;
	grid-template-columns: 1fr 1fr 1fr;
`

export const TextArea = styled.textarea`
	width: 100%;
	appearance: none;
	outline: none;
	color: white;
	border: none;
	padding: 8px;
	font-size: 1.1rem;
	position: relative;
	background-color: transparent;
	border-bottom: 2px solid #505050;
`
