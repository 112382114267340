import styled from 'styled-components'

export const Wrapper = styled.div`
	margin: 10px 0;
	padding-left: 1rem;
	border-left: 4px dashed #eee;
`

export const Destination = styled.div`
	position: relative;

	h4 {
		font-weight: 600;
		color: #fff;
	}

	p {
		margin-left: 0.7rem;
		font-size: 14px;
		color: #fff;
	}

	a {
		color: var(--fooy);
	}

	& span {
		font-weight: 600;
	}

	&::before {
		content: '';
		width: 9px;
		height: 9px;
		background: var(--fooy);
		border-radius: 50%;
		position: absolute;
		left: -22px;
		top: 5px;
	}

	&:not(:last-child) {
		margin-bottom: 1rem;
	}
`
