import React, { useEffect } from 'react'
import { useHistoryContext } from '../../context/history'
import { HistoryItem } from './HistoryItem'
import { FaRedoAlt } from 'react-icons/fa'
import styled from '@emotion/styled'

export default ({ type = 'todos' }: { type?: 'em_andamento' | 'todos' }) => {
  const { loading, historys, loadHistory } = useHistoryContext()

  useEffect(() => {
    loadHistory()
    setInterval(() => {
      loadHistory()
    }, 15000)
  }, [])

  const onlyIsDelivered = historys.filter(item => item.tripStatus !== 9)

  return (
    <React.Fragment>
      {type === 'todos' ? (
        <>
          {/* <div style={{padding: 10, textAlign:"center", color:"#EEE"}}>{loading ? 'Atualizando...' : (<b>Solicitações</b>)}</div> */}
          {historys.length > 0 ? (
            <ListGrid>
              {historys.map((item, index) => (
                <HistoryItem key={index} trip={item} />
              ))}
            </ListGrid>
          ) : (
            <EmptyList>{loading ? '' : 'Sem registros'}</EmptyList>
          )}
        </>
      ) : (
        <>
          {/* <div style={{padding: 10, textAlign:"center", color:"#EEE"}}>{loading ? 'Atualizando...' : (<b>Solicitações</b>)}</div> */}
          {onlyIsDelivered.length > 0 && (
            <ListGrid>
              {historys.map((item, index) => (
                <HistoryItem key={index} trip={item} />
              ))}
            </ListGrid>
          )}
          {/* {type !== 'em_andamento' && onlyIsDelivered.length === 0 && (
            <EmptyList>{loading ? '' : 'Sem registros'}</EmptyList>
          )} */}
        </>
      )}
    </React.Fragment>
  )
}

export const ListGrid = styled.ul`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  grid-auto-rows: min-content;
  list-style: none;
`

export const Container = styled.div`
  overflow-x: hidden;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: #4457bb;
    border-radius: 10px;
    opacity: 0;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #4457bb;
  }
`

const EmptyList = styled.p`
  position: relative;
  margin: 20px auto;
  font-size: 20px;
  color: white;
`

const ReloadButton = styled.button`
  position: absolute !important;
  overflow-y: hidden;
  bottom: 2.25rem;
  right: 2.25rem;
  width: 3rem;
  height: 3rem;
  appearance: none;
  outline: none;
  border: none;
  cursor: pointer;
  border-radius: 50%;
  background-color: #4457bb;
  color: #fff;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.4);
  transition: all 200ms ease;
`

