import React from 'react'
import { FaCalendarDay } from 'react-icons/fa'
import { Button } from './styles'

interface ScheduleButtonProps extends React.HTMLProps<HTMLButtonElement> {}

export const ScheduleButton = React.memo((props: ScheduleButtonProps) => (
	<Button {...props}>
		<FaCalendarDay />
		<span>Agendar</span>
	</Button>
))
