import React from 'react'
import styled from '@emotion/styled'
import { displayTrip, DisplayProviderStatus } from '../../History/HistoryItem'
import { Item } from '../../History/HistoryItem/styles'
import { PlannedTrip } from '../../../context/trip/tripProvider.type'
import { HistoryDestination } from '../../History/HistoryDestination'
import { PlannedOptions } from '../PlannedOptions'
import { Footer, MapButton } from './styles'

interface PlannedItemProps {
	optionsDisabled: boolean
	item: PlannedTrip
	cancel(): void
	confirm(): void
}

export const PlannedItem = ({ item, cancel, confirm, optionsDisabled }: PlannedItemProps) => {
	return (
		<Item>
			<HistoryDestination open={true} destinations={item.destinations} />
			<Footer>
				<div>
					<h3 className="title is-6">Distância</h3>
					<p className="subtitle is-6">{item.distance} km</p>
				</div>
				<div>
					<h3 className="title is-6">Pagamento</h3>
					<p className="subtitle is-6">R$ {item.amount}</p>
				</div>
				<div>
					<h3 className="title is-6">Status</h3>
					<p className="subtitle is-6">
            <DisplayProviderStatus status={item.status} is_rated={0}/>
					</p>
				</div>
			</Footer>
			<PlannedOptions
				cancelPlannedTrip={cancel}
				makePlannedTrip={confirm}
				disabled={optionsDisabled}
			/>
			<MapButton onClick={() => displayTrip(item.destinations)} />
		</Item>
	)
}
