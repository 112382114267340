import styled from 'styled-components';

export const Container = styled.div`
margin-top:20px;
display: grid;
grid-gap: 20px;
height: calc(100vh - 125px);
grid-template-columns: 1fr;
grid-template-rows: min-content min-content min-content;
`

export const Info = styled.p`
margin-left: 0px;
font-size: 14px;
color: white;
`

export const Header = styled.div`
--display: grid;
grid-gap: 1rem;
grid-template-columns: max-content max-content;

& > span {
  align-self: end;
  font-size: 0.9rem;
  color: #F55;
}
`

export const Footer = styled.footer`
display: grid;
grid-template-columns: max-content 1rem max-content 0.2rem 1fr;
grid-template-areas: 'type . schedule . submit';
`