import React, { useState, useCallback } from 'react'
import { useToggle, useLoadingState } from '../../../../lib/hooks'
import { FaTimesCircle } from 'react-icons/fa'
import {

} from '../../../History/ModalConfirm'
import { ScheduleButton } from './ScheduleButton'
import { Modal } from '../../../../components/PlaceInput/Modal'
import { DatePicker } from './DataPickerInput'

import { Loading } from '../../../../components/Loading'
import {
	Container,
	Header,
	CloseButton,
	CancelButton,
} from '../../../History/ModalConfirm/styles'
import { Confirm, Content, Footer } from './styles'

interface Prop {
	disabled?: boolean
	action(date: string, callback: () => void): void
}

export const ScheduleModal = ({ disabled, action }: Prop) => {
	const { active, disabled: closeModal, enabled } = useToggle(false)
	const { active: loading, enabled: loadingStart, disabled: loadingEnd } = useToggle(false)

	const [date, setDate] = useState<string | null>(null)

	const makeSchedule = useCallback(async () => {
		if (!date) return

		loadingStart()
		action(date, () => {
			loadingEnd()
			closeModal()
		})
	}, [date])

	const disabledConfirm = !Boolean(date)

	if (active) {
		return (
			<React.Fragment>
				<ScheduleButton />
				<Modal close={closeModal}>
					<Container>
						<Header>
							<h1>Horário para agendamento</h1>
							<CloseButton onClick={closeModal} disabled={loading}>
								<FaTimesCircle />
							</CloseButton>
						</Header>
						<Content>
							<DatePicker onChange={setDate} />
						</Content>
						<Footer>
							<CancelButton onClick={closeModal} disabled={loading}>
								Cancelar
							</CancelButton>
							<Confirm disabled={disabledConfirm || loading} onClick={makeSchedule}>
								{loading ? <Loading /> : 'Confirmar'}
							</Confirm>
						</Footer>
					</Container>
				</Modal>
			</React.Fragment>
		)
	}

	return <ScheduleButton onClick={enabled} disabled={disabled} />
}