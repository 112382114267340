import React from 'react'
import ReactDom from 'react-dom'
import { Background, Container } from './styles'

const modalRoot = document.getElementById('modal')!

interface Props {
	close(e: React.MouseEvent<any>): void
}

export const Modal: React.SFC<Props> = ({ close, children }) => {
	return ReactDom.createPortal(
		<Container>
			<Background onClick={close} />
			{children}
		</Container>,
		modalRoot
	)
}
