// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getDatabase } from "firebase/database";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyD_bDFAEgmIKZgJzlCoxnIJWh71JGRPjHU",
  authDomain: "vaay-monitor.firebaseapp.com",
  projectId: "vaay-monitor",
  storageBucket: "vaay-monitor.appspot.com",
  messagingSenderId: "489303885054",
  appId: "1:489303885054:web:76692db2c3b0bb1619d1af"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Realtime Database and get a reference to the service
export const database = getDatabase(app);