import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { Container as ListContainer, ListGrid } from '../History'
import { useTrip } from '../../context/trip'
import { Dropdown } from '../../components/Dropdown'
import { PlannedItem } from './PlannedItem'
import { DeliveryType } from './DeliveryType'
import { useFormContext } from '../../context/form'
import { EmptyList, Header, RequiredFields, Wrapper } from './styles'

export const Planned = () => {
	const { plannedTrips, getPlannedTrip, confirmPlannedTrip, cancelPlannedTrip } = useTrip()

	const [typeId, setTypeId] = useState<string | null>(null)

	const {
		options,
		onChangeCostCenter,
		state: { cost_center_id }
	} = useFormContext()

	useEffect(() => {
		if (cost_center_id) getPlannedTrip(cost_center_id)
	}, [cost_center_id])

	const optionsDisabled = Boolean(!(typeId && cost_center_id))

	return (
		<Wrapper>
			<Header>
				<Dropdown
					label="Local de origem"
					value={cost_center_id}
					onChange={onChangeCostCenter}
					options={options}
				/>
			</Header>

			<RequiredFields>Selecione uma Categoria</RequiredFields>

			<DeliveryType value={typeId} updateCallback={setTypeId} />

			<ListContainer>
				{plannedTrips.length > 0
					? <ListGrid>
						{plannedTrips.map(plannedTrip => (
							<PlannedItem
								item={plannedTrip}
								cancel={cancelPlannedTrip(plannedTrip._id, cost_center_id!)}
								confirm={confirmPlannedTrip(plannedTrip._id, cost_center_id!, typeId!)}
								optionsDisabled={optionsDisabled}
							/>
						))}
					</ListGrid>
					: <EmptyList>Sem registros</EmptyList>}
			</ListContainer>
		</Wrapper>
	)
}