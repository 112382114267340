import styled from 'styled-components'
import { ConfirmButton } from '../../../History/ModalConfirm/styles'

export const Confirm = styled(ConfirmButton)`
	&:disabled {
		background: #9e9e9e;
	}
`
export const Content = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 1rem;
`

export const Footer = styled.div`
	display: flex;
	justify-content: space-around;
	padding: 1rem;
`
