import styled from 'styled-components'

export const Button = styled.button`
	height: 100%;
	width: 100%;
	border: none;
	border-radius: 4px 0 0 4px;
	font-size: 1.1em;
	font-weight: 600;
	color: white;
	padding: 1em;
	cursor: pointer;
	background: var(--fooy);
	transition: all 200ms;
	position: relative;

	&:hover {
		opacity: 0.9;
	 }

	&:disabled {
		pointer-events: none;
		background: #757575;
	}

	&:active {
		transform: scale(0.85);
		box-shadow: inset 0px 0px 50px var(--fooy);
	}

	& span {
		opacity: 0;
		transition: all 300ms ease;
		position: absolute;
		padding: 0.4rem;
		color: #fff;
		pointer-events: none;
		background: #757575;
		top: 0;
		left: 0;
	}

	&:hover span {
		opacity: 1;
		transform: translateY(-105%);
	}
`
