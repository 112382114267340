import React, { useEffect, useMemo } from 'react'
import styled from '@emotion/styled'
import { ConfirmationTypesOptions } from '../../../../../context/form/form.types'
import { useToggle } from '../../../../../lib/hooks'
import { cx } from '../../../../../lib/classnames'
import { IconType } from 'react-icons/lib/cjs'
import { FaAngleDown } from 'react-icons/fa'
import { Container, Item, Menu, Trigger } from './styles'

interface Props {
	label: string
	value: string | any
	icon: IconType
	options: ConfirmationTypesOptions[]
	updateCallback(type: string): void
}
 
export const ConfirmationType: React.SFC<Props> = ({ options, label, value, updateCallback }) => {
	const { active: open, toggle } = useToggle(false)

	const selectedValue = useMemo(() => {
		const option = options.find(option => option.type === value)
		return option ? option.label : ''
	}, [value, options])

	useEffect(() => {
		if (open) {
			document.addEventListener('click', toggle)
		}

		return () => {
			document.removeEventListener('click', toggle)
		}
	}, [open])

	return (
		<Container>
			<span className="label">{label}</span>

			<Trigger onClick={toggle}>
				<span>{selectedValue}</span>
				<FaAngleDown />
			</Trigger>

			{open && (
				<Menu>
					{options.map(({ type, label, icon: Icon }) => (
						<Item
							key={type}
							onClick={() => updateCallback(type)}
							className={cx({ selected: type === value })}
						>
							<Icon />
							<span>{label}</span>
						</Item>
					))}
				</Menu>
			)}
		</Container>
	)
}

