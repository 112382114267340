import React from 'react'

import { Input, Label } from './styles'

interface CustomInputProps extends React.HTMLProps<HTMLInputElement> {
	icon?: React.ReactNode
}

export const CustomInput = React.memo(({ icon, ...props }: CustomInputProps) => {
	return (
		<Label>
			<Input {...props} />
			{icon}
		</Label>
	)
})
