import React, { useCallback } from 'react'

import { FaAngleDown, FaHandPaper, FaCheckCircle } from 'react-icons/fa'
import { Wrapper, Trigger, Menu, ItemContainer, Item } from '../../History/HistoryOptions/styles'
import { ModalConfirm } from '../../History/ModalConfirm'
import { useToggle } from '../../../lib/hooks'

interface PlannedOptionsProps {
	cancelPlannedTrip(): void
	makePlannedTrip(): void
	disabled: boolean
}

export const PlannedOptions = ({
	cancelPlannedTrip,
	makePlannedTrip,
	disabled
}: PlannedOptionsProps) => {
	const { active, toggle, disabled: closeOption } = useToggle(false)

	const cancelTrip = useCallback(() => {
		cancelPlannedTrip()
		closeOption()
	}, [cancelPlannedTrip])

	const makeTrip = useCallback(() => {
		makePlannedTrip()
		closeOption()
	}, [makePlannedTrip])

	return (
		<Wrapper>
			<Trigger onClick={toggle} disabled={disabled}>
				<span>Opções</span>
				<FaAngleDown />
			</Trigger>
			{active && (
				<Menu>
					<ItemContainer>
						<ModalConfirm
							title="Confirmar cancelamento?"
							action={cancelTrip}
							trigger={prop => (
								<Item onClick={prop.action} className="negative">
									<FaHandPaper />
									<span>Cancelar</span>
								</Item>
							)}
						/>

						<ModalConfirm
							title="Deseja finalizar está Entrega?"
							action={makeTrip}
							trigger={prop => (
								<Item onClick={prop.action} className="positive">
									<FaCheckCircle />
									<span>Realizar Pedido</span>
								</Item>
							)}
						/>
					</ItemContainer>
				</Menu>
			)}
		</Wrapper>
	)
}
