import styled, { css } from 'styled-components'

export const Container = styled.div`
	min-width: 25rem;
	background: var(--fooy-sec-background);
	z-index: 1;
	border-radius: 5px;
`

export const Content = styled.div`
	display: flex;
	justify-content: space-around;
	padding: 2rem 2rem;
`

export const CloseButton = styled.button`
	appearance: none;
	background: none;
	border: none;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 2rem;
	color: #fff;
	cursor: pointer;
	width: 80px;

	transition: all 0.2s;

	&:hover {
		background: var(--fooy-cancel);
	}
`

export const Header = styled.div`
	height: 4rem;
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid var(--fooy-dark);
	background: var(--fooy-background);
	border-radius: 5px 5px 0 0;

	& h1 {
		font-size: 1.2rem;
		font-weight: 800;
		color: #fff;
		text-align: center;
		vertical-align: middle;
		align-self: center;
		margin-left: 1rem;
	}
`

export const baseButton = css`
	border: none;
	cursor: pointer;
	text-align: center;
	vertical-align: middle;
	font-size: 1.2rem;
	font-weight: 600;
	border-radius: 5px;
	box-sizing: border-box;
	height: 3.5rem;
	width: 8rem;

	transition: opacity 0.2s;

	&:hover {
		opacity: 0.8;
	}

	&:disabled {
		cursor: default;
		opacity: 1;
	}
`

export const ConfirmButton = styled.button`
	${baseButton};
	color: #fff;
	background: var(--fooy);
`

export const CancelButton = styled.button`
	${baseButton};
	/* color: var(--fooy-cancel);
	border: 2px solid var(--fooy-cancel); */
	color: #fff;
	background: var(--fooy-cancel);
`
