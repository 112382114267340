import styled from 'styled-components';

export const Container = styled.div`
flex: 1;
align-self: center;
display: flex;
justify-content: center;
`

export const Star = styled.label`
display: inline-block;
padding: 3px;
vertical-align: middle;
line-height: 1;
font-size: 2rem;
color: #ababab;
transition: color 0.2s ease-out;

&:hover {
  cursor: pointer;
}

&.is-selected {
  color: var(--fooy);
}
`