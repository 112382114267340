import React, { useCallback } from 'react'
import { FaPlay, FaForward, FaBicycle, FaDollarSign } from 'react-icons/fa'
import { IconType } from 'react-icons/lib/cjs'

import { Container, Input, Label } from './styles'

interface Props {
	value: string | null
	updateCallback(e: string): void
}

const types: { label: string; type: string; icon: IconType }[] = [
	// {
	// 	label: 'Bike', //Bike
	// 	type: '5c61fdca8f81a04505e2d6a8',
	// 	icon: FaBicycle
	// },
	{
		label: 'Calcular valor', //Moto
		type: '5c48c5ded8f8a95a31425b23',
		icon: FaDollarSign
	},
	// {
	// 	label: 'Express+', //Carro
	// 	type: '5e7bcd291c9d4400004ed8f9',
	// 	icon: FaForward
	// }
] 

export const DeliveryType = ({ value, updateCallback }: Props) => {
	return (
		<Container>
			{types.map(({ label, type, icon: Icon }) => (
				<React.Fragment key={type}>
					<Input
						hidden
						id={type}
						type="radio"
						value={type}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
							updateCallback(e.target.value)
						}}
						onClick={(e: React.MouseEvent<HTMLInputElement>) => {
							updateCallback(e.currentTarget.value)
						}}
						checked={type === value}
					/>
					<Label htmlFor={type}>
						<Icon />
						<span>{label}</span>
					</Label>
				</React.Fragment>
			))}
		</Container>
	)
}
