import styled from 'styled-components';

export const Container = styled.div`
	position: relative;
	margin-bottom: 16px;
`

export const Input = styled.input`
	display: block;
	padding: 15px 10px;
	margin-bottom: 10px;
	width: 100%;
	border: none;
	box-shadow: 0 3px 0 #ccc;
	border-radius: 20px;
	color: #ccc;
	font-size: 1.05em;

	/* &:hover{
		border: 0px solid var(--fooy-dark);
		box-shadow: 0 2px 0 var(--fooy-dark);
	} */

	& + svg {
		color: #fff;
		font-size: 1em;
		position: absolute;
		margin-top: -42px;
		opacity: 0;
		left: 0;
		transition: all 0.1s ease-in;
	}

	&:focus {
		outline: none;
		color: #444;
		/* border-color: var(--fooy); */
		border-left-width: 35px;
	}

	&:focus + svg {
		opacity: 1;
		left: 9px;
	}
`