import origin from '../../assets/user.svg'
import provider from '../../assets/_provider.svg'
import destination from '../../assets/destination.svg'

import Location from '../geoLocation'

import { DeferOverlay, Overlay } from './overlayMap'

//@ts-ignore
import MarkerClusterer from '@google/markerclustererplus'
import cluster from '../../assets/map_cluster.svg'

//@ts-ignore
import debounce from 'lodash.debounce'
import { STYLES } from './style'
import { animateTo } from './animateTo'

declare global {
	interface Window {
		init(): void
		Corigin(): void
		Cdestination(): void
		renderMap(): void
	}
}

type Address = { position: google.maps.LatLng; formated_address: string }

const mapsScript = `https://maps.googleapis.com/maps/api/js?key=AIzaSyA1EWxwJ6-Mtbv-Ihn2jW34ywyVLD3PuEY&libraries=places&callback=init`

const initialPosition = {
	lat: -23.55,
	lng: -46.64
}

class Map {
	private destination?: google.maps.Marker

	private defaultBounds?: google.maps.LatLngBounds | null
	private path?: google.maps.Polyline
	private map?: google.maps.Map
	private overlay?: Overlay

	private paths: google.maps.Polyline[] = []
	public wayPoints: google.maps.LatLngLiteral[] = []
	public wayPointsMarker: google.maps.Marker[] = []

	public getDistanceCallback?(distanceText: string, distanceValue: number): void

	ready: boolean = false
	container?: HTMLDivElement
	private queue: Array<Function> = []
	private clusters?: MarkerClusterer

	private providers: google.maps.Marker[] = []
	public location?: google.maps.Marker

	private showWayPoint: any = null
	// track feature
	private providerLocation?: google.maps.Marker
	private providerPath?: google.maps.Polyline

	constructor() {
		this.showWayPoint = debounce(this._showWayPoint, 500)
		this.container = document.createElement('div')
		this.container!.style.flex = '1'
	}

	addToQueue = (func: Function) => {
		this.queue.push(func)
	}

	runQueue = () => {
		google.maps.event.addListenerOnce(this.map!, 'idle', () => {
			this.queue.forEach(fn => fn())
			this.queue = []
		})
	}

	async init(element: HTMLElement, options?: any): Promise<any> {
		if (!this.ready) {
			window.init = () => {
				this.ready = true
				this.init(element, options)
				this.runQueue()
			}

			const script = document.createElement('script')
			script.type = 'text/javascript'
			script.src = mapsScript
			document.body.appendChild(script)
			return
		}

		// console.log(options)

		if (!this.map) {
			await this.setupMap(options)
		}

		element.appendChild(this.container!)
	}

	async setupMap(options?: google.maps.MapOptions): Promise<any> {
		this.map = new google.maps.Map(this.container!, {
			disableDoubleClickZoom: true,
			streetViewControl: false,
			fullscreenControl: false,
			mapTypeControl: false,
			disableDefaultUI: true,
			center: initialPosition,
			scrollwheel: false,
			draggable: false,
			styles: STYLES,
			zoom: 5,
			...options
		})
		this.defaultBounds = this.map.getBounds()
		Location().then(location => {
			if (this.map) {
				this.map.setCenter(location)
				this.map.setZoom(15)
				this.runQueue()
			}
		})
	}

	async setOrigin(location: google.maps.LatLngLiteral): Promise<any> {
		if (!location) return

		if (this.map) {
			if (this.location) this.location.setMap(null)

			this.location = new google.maps.Marker({
				map: this.map,
				position: location,
				animation: google.maps.Animation.DROP,
				icon: {
					url: origin,
					size: new google.maps.Size(50, 88),
					anchor: new google.maps.Point(25, 44)
				}
			})

			// this.location.setPosition(location)
			// this.location.setAnimation(google.maps.Animation.DROP)
			this.map.setCenter(location)
			this.map.setZoom(15)
			// this.location.setMap(this.map)
		} else {
			this.addToQueue(() => this.setOrigin(location))
		}
	}

	showProviderLocation(locations: google.maps.LatLngLiteral[]) {
		if (this.map) {
			this.providers.forEach(provider => provider.setMap(null))
			if (this.clusters) {
				this.clusters.clusters_.forEach((cluster: any) => cluster.remove())
			}

			this.providers = locations.map(location => {
				return new google.maps.Marker({
					map: this.map,
					position: location,
					draggable: false,
					icon: {
						url: provider,
						size: new google.maps.Size(20, 20),
						scaledSize: new google.maps.Size(20, 20),
						anchor: new google.maps.Point(50, 50)
					}
				})
			})

			this.clusters = new MarkerClusterer(this.map, this.providers, {
				styles: [
					{
						url: cluster,
						width: 56,
						height: 56,
						textColor: '#fff',
						textSize: 16
					}
				]
			})
		} else {
			this.addToQueue(() => this.showProviderLocation(locations))
		}
	}

	addWayPoint = async (
		location: google.maps.LatLngLiteral,
		idx?: number
	): Promise<string | null> => {
		this.path && this.path.setMap(null)
		this.wayPointsMarker.forEach(point => point.setMap(null))

		if (idx !== undefined && typeof idx === 'number') {
			this.wayPoints[idx] !== undefined
				? (this.wayPoints[idx] = location)
				: this.wayPoints.push(location)
		} else {
			this.wayPoints.push(location)
		}

		return await this.showWayPoint()
	}

	removeAllPoints = () => {
		this.wayPoints = []
		this.wayPointsMarker.forEach(point => point.setMap(null))
		this.paths.forEach(path => path.setMap(null))
		this.paths = []
	}

	removeWayPoint = async (idx: number): Promise<string | null> => {
		if (this.wayPoints.length) {
			this.wayPoints.splice(idx, 1)
			this.wayPointsMarker.forEach(point => point.setMap(null))
			this.path && this.path.setMap(null)
			return await this.showWayPoint()
		}
		return null
	}

	_showWayPoint = (): Promise<string | null> => {
		return new Promise(resolve => {
			if (!this.map) resolve(null)

			const directions = new google.maps.DirectionsService()

			const waypoints = this.wayPoints.map(point => ({
				location: point,
				stopover: false
			}))

			this.wayPointsMarker = waypoints.map(
				point =>
					new google.maps.Marker({
						map: this.map,
						position: point.location,
						icon: {
							url: destination,
							size: new google.maps.Size(58, 58),
							scaledSize: new google.maps.Size(40, 40),
							anchor: new google.maps.Point(20, 40)
						}
					})
			)

			if (this.location && this.wayPoints.length > 0) {
				const origin = this.location.getPosition()
				const { lat, lng } = this.wayPoints[this.wayPoints.length - 1]
				const destination = new google.maps.LatLng(lat, lng)

				directions.route(
					{
						waypoints,
						origin,
						destination,
						travelMode: google.maps.TravelMode.DRIVING,
						optimizeWaypoints: true
					},
					(result, status) => {
						if (status === google.maps.DirectionsStatus.OK) {
							const [route, ..._] = result.routes

							const path = google.maps.geometry.encoding.decodePath(route.overview_polyline)

							const { text: distanceText, value: distanceValue } = route.legs[0].distance

							this.map && this.map.fitBounds(route.bounds, 20)

							this.path = new google.maps.Polyline({
								map: this.map,
								strokeColor: '#11cf99',
								strokeWeight: 4,
								geodesic: true,
								path
							})

							this.paths.push(this.path)
							if (this.getDistanceCallback) {
								this.getDistanceCallback(distanceText, distanceValue)
							}
						} else resolve(null)
					}
				)
			}

			resolve(null)
		})
	}

	updateProviderPosition(location: google.maps.LatLngLiteral) {
		if (this.map) {
			if (this.providerLocation) {
				animateTo(this.providerLocation, location)
			} else {
				this.map.setCenter(location)
				this.map.setZoom(15)

				this.providerLocation = new google.maps.Marker({
					map: this.map,
					position: location
				})
			}
		} else {
			this.addToQueue(() => {
				this.updateProviderPosition(location)
			})
		}
	}

	updateProviderRoute(locations: google.maps.LatLngLiteral[]) {
		if (this.providerPath) {
			locations.forEach(loc => {
				window.requestAnimationFrame(() =>
					this.providerPath!.getPath().push(new google.maps.LatLng(loc.lat, loc.lng))
				)
			})
		} else {
			this.providerPath = new google.maps.Polyline({
				map: this.map,
				strokeColor: '#11cf99',
				strokeOpacity: 1.0,
				strokeWeight: 4,
				editable: false,
				geodesic: true,
				zIndex: 5,
				path: []
			})

			this.updateProviderRoute(locations)
		}
	}

	searching(): void {
		if (this.map && this.location) {
			const position = this.location.getPosition()
			this.defaultBounds = this.map.getBounds()
			this.map.setOptions({
				// scrollwheel: false,
				// draggable: false,
				center: position
			})
			this.overlay = new (DeferOverlay())(this.map, position)
		}
	}

	hideSearch(): void {
		this.wayPointsMarker.forEach(point => point.setMap(null))
		this.paths.forEach(path => path.setMap(null))
		if (this.overlay && this.map && this.defaultBounds) {
			this.overlay.hide()
			this.map.fitBounds(this.defaultBounds)
			this.map.setOptions({
				scrollwheel: true,
				draggable: true
			})
		}
	}

	reset(): void {
		this.destination && this.destination.setMap(null)
		this.path && this.path.setMap(null)
		this.map && this.defaultBounds && this.map.fitBounds(this.defaultBounds)
	}

	destroy(): void {
		this.destination = undefined
		this.overlay = undefined
		this.location = undefined
		this.path = undefined
		this.map = undefined
	}
}

export default new Map()
