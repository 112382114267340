import React from 'react'
import { formatPhone } from '../../../lib/masks'
import { ITrip, Destination } from '../../../context/history/history.type'
import { HistoryDestination } from '../HistoryDestination'
import { HistoryOptions } from '../HistoryOptions'
import { useToggle } from '../../../lib/hooks'
import { FaMapMarked, FaCaretDown, FaCaretUp, FaWhatsapp } from 'react-icons/fa'
import Map from '../../../lib/Map'
import {
  FooterContainer,
  Item,
  MapButton,
  PictureContainer,
  WhatsAppButtonStyle,
} from './styles'

// status
// 0: Aguaradndo confirmação
// 2: Entregador a caminho
// 4: Entregador chegou
// 6: Entrega iniciada
// 8: Retornando parao estabelecimento
// 9: Entrega finalizada
export const DisplayProviderStatus = ({
  status,
  is_rated,
}: {
  status: number
  is_rated: number
}) => {
  switch (status) {
    case 2:
      return <span className="has-text-warning">Entregador a caminho</span>
    case 4:
      return <span className="has-text-warning">Entregador chegou</span>
    case 6:
      return <span className="has-text-warning">Entrega iniciada</span>
    case 8:
      return (
        <span className="has-text-warning">
          Retornando para o estabelecimento
        </span>
      )
    case 9:
      return (
        <span className="has-text-success">
          Entrega finalizada{' '}
          {is_rated == 1 ? (
            <span style={{ color: '#222' }}>&bull; Avaliado</span>
          ) : (
            ''
          )}
        </span>
      )
    default:
      return <span className="has-text-white">Aguardando confirmação</span>
  }
}

export function displayTrip(destination: Destination[]) {
  Map.removeAllPoints()

  destination.forEach(({ coordinates }) => {
    const [lat, lng] = coordinates
    Map.addWayPoint({ lat, lng })
  })
}

interface Props {
  // finishTrip(id: string): (rating: number, callback: () => void) => void
  // cancelTrip(id: string): any
  trip: ITrip
}

export const HistoryItem = ({ trip }: Props) => {
  const { toggle, active } = useToggle(false)
  const canTrack = trip.is_delivery_completed == 0

  return (
    <Item>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }} />

      <div style={{ display: 'flex', marginBottom: 10, position: 'relative' }}>
        <ProviderPicture image={trip.providerPicture} />
        <div>
          <p style={{ marginBottom: 4, fontWeight: 500, color: 'white' }}>
            {trip.providerName
              .split(' ')
              .slice(0, -1)
              .join(' ')}
          </p>
          <p style={{ color: 'white' }}>{formatPhone(trip.providerPhone)}</p>
          <p style={{ color: 'white' }}>{trip.tripDate}</p>
        </div>
        {canTrack && <HistoryOptions trip={trip} />}
        <WhatsAppButton
          onClick={() =>
            window.open('https://wa.me/55' + trip.providerPhone, '_blank')
          }
        />
        <DisplayMapButton onClick={() => displayTrip(trip.destinations)} />
      </div>

      <HistoryDestination open={active} destinations={trip.destinations} />

      <FooterContainer onClick={toggle}>
        <div>
          <h3 className="title is-7" style={{ color: '#23d160' }}>
            {trip.unique_id}
          </h3>
        </div>
        <div>
          <p className="subtitle is-6">
            <DisplayProviderStatus
              status={trip.tripStatus}
              is_rated={trip.is_delivery_completed}
            />
          </p>
        </div>
        {active ? (
          <FaCaretUp style={{ color: '#11cf99' }} />
        ) : (
          <FaCaretDown style={{ color: '#11cf99' }} />
        )}
      </FooterContainer>
    </Item>
  )
}

interface Prop {
  image: string
}

export const ProviderPicture = React.memo(({ image }: Prop) => (
  <PictureContainer>
    <img src={image} />
  </PictureContainer>
))

export const DisplayMapButton: React.SFC<
  React.HTMLProps<HTMLButtonElement>
> = props => (
  <MapButton {...props}>
    <FaMapMarked />
  </MapButton>
)

export const WhatsAppButton: React.SFC<
  React.HTMLProps<HTMLButtonElement>
> = props => (
  <WhatsAppButtonStyle {...props}>
    <FaWhatsapp />
  </WhatsAppButtonStyle>
)

