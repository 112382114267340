import React, { Suspense } from 'react'
import { useLoginState } from '../context/auth/loginState'
import { Redirect, Switch, Route } from 'react-router-dom'

import { Login, Panel, Track } from '../views'
import InvoiceDetail from '../views/login/Invoice_details'
import { HistoryProvider } from '../context/history'
import { TripProvider } from '../context/trip'
import { PlaceSuggestionProvider } from '../context/places'
import { LoadindScreen } from './LoadingScreen'
import { FormProvider } from '../context/form'

const Routes = () => {
	const isLogin = useLoginState()

	return isLogin ? (
		<HistoryProvider>
			<PlaceSuggestionProvider>
				<TripProvider>
					<FormProvider>
						<Switch>
							<Route path="/" component={Panel} />
							<Route exact path="/login" component={Login} />
							<Route exact path="/invoice/:invoice_id" component={InvoiceDetail} />
							<Route exact path="/track/:tripId" component={Track} />
							<Route render={() => <Redirect to="/login" />} />
						</Switch>
					</FormProvider>
				</TripProvider>
			</PlaceSuggestionProvider>
		</HistoryProvider>
	) : (
		<Redirect to="/login" />
	)
}

export const PanelRoute = ({ ...props }) => (
	<Suspense fallback={<LoadindScreen />}>
		<Route {...props} component={Routes} />
	</Suspense>
)
