/*global google*/

type searchParameter = {
	placeId?: string
	location?: google.maps.LatLngLiteral
}

export const getLocation = (search: searchParameter): Promise<google.maps.LatLngLiteral> => {
	const geocoder = new google.maps.Geocoder()

	return new Promise((resolve, reject) => {
		geocoder.geocode(
			search,
			(results: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) => {
				if (status === google.maps.GeocoderStatus.OK) {
					const [result, ..._] = results
					const { lat, lng } = result.geometry.location.toJSON()

					resolve({ lat, lng })
				} else reject('Falha ao obter Endereço.')
			}
		)
	})
}

export interface Address {
	street: string
	neighborhood: string
	postal_code: string
	country: string
	state: string
	city: string
	number: string
	complement: string
	location: google.maps.LatLngLiteral
}

function parseAddress(result: google.maps.GeocoderResult) {
	return result.address_components.reduce(
		(acc: Address, component) => {
			if (component.types.includes('street_number')) {
				acc['number'] = component.long_name
			} else if (component.types.includes('postal_code')) {
				acc['postal_code'] = component.long_name
			} else if (component.types.includes('sublocality_level_1')) {
				acc['neighborhood'] = component.long_name
			} else if (component.types.includes('administrative_area_level_1')) {
				acc['state'] = component.short_name
			} else if (component.types.includes('administrative_area_level_2')) {
				acc['city'] = component.short_name
			} else if (component.types.includes('country')) {
				acc['country'] = component.long_name
			}
			return acc
		},
		{} as Address
	)
}
