import React, { useEffect } from 'react'
import { Container as ListContainer, ListGrid } from '../History'
import { Dropdown } from '../../components/Dropdown'
import { Header, Wrapper } from '../planned/styles'
import { useTrip } from '../../context/trip'
import { ScheduleItem } from './ScheduleItem'
import { Loading } from '../../components/Loading'
import { useFormContext } from '../../context/form'
import styled from '@emotion/styled'
import { EmptyList } from './styles'

export const Schedule = () => {
  const {
    getSchedules,
    schedulesTrips,
    cancelScheduleTrip,
    loading,
  } = useTrip()

  const { state, onChangeCostCenter, options } = useFormContext()

  useEffect(
    () => {
      if (state.cost_center_id) getSchedules(state.cost_center_id)
    },
    [state]
  )

  return (
    <Wrapper>
      <Header>
        <Dropdown
          value={state.cost_center_id}
          onChange={onChangeCostCenter}
          options={options}
          label="Local de origem"
        />

        {!state.cost_center_id && (
          <span style={{ alignSelf: 'flex-end' }}>Selecione um local</span>
        )}
      </Header>
      <ListContainer>
        {schedulesTrips.length > 0 ? (
          <ListGrid>
            {schedulesTrips.map(schedule => (
              <ScheduleItem
                key={schedule._id}
                item={schedule}
                cancel={cancelScheduleTrip(schedule._id, state.cost_center_id!)}
              />
            ))}
            {loading && <Loading style={{ justifySelf: 'center' }} />}
          </ListGrid>
        ) : (
          <EmptyList>Sem registros</EmptyList>
        )}
      </ListContainer>
    </Wrapper>
  )
}
