import React from 'react'
import styled from '@emotion/styled'
import { RouteComponentProps } from 'react-router'
import { MapContainer } from '../panel/Components/Map/Map'
import { FaPhone } from 'react-icons/fa'
import { useTrackState, useTripInfo } from './state'
import { HistoryDestination } from '../../container/History/HistoryDestination'
import { cx } from '../../lib/classnames'

interface Prop
	extends RouteComponentProps<{
		tripId: string
	}> {}

export default ({
	match: {
		params: { tripId }
	}
}: Prop) => {
	useTrackState(tripId)

	const { trip, provider, loading } = useTripInfo(tripId)

	const renderProvider = () => {
		return (
			<Wrapper className={cx({ visible: trip && provider })}>
				{provider && trip && (
					<Provider>
						<ProviderPhoto src={`https://api.vaay.app/storage/${provider.picture}`} />

						<ProviderInfo>
							<h4>
								{provider.first_name} {provider.last_name}
							</h4>
							<p>
								{trip.type_name}
								<ProviderPhone href={`https://web.whatsapp.com/send?phone=+55${provider.phone}`}>
									<FaPhone />
									<span>{provider.phone}</span>
								</ProviderPhone>
							</p>
						</ProviderInfo>
					</Provider>
				)}
			</Wrapper>
		)
	}

	const renderDestinations = () => {
		if (trip) {
			return (
				<DestinationContainer>
					<HistoryDestination open={true} destinations={trip.destinations} />
				</DestinationContainer>
			)
		}

		return null
	}

	return (
		<Container>
			<MapContainer
				mapOptions={{
					draggable: true,
					disableDefaultUI: false,
					scrollwheel: true
				}}
			/>
			{renderProvider()}
			{renderDestinations()}
		</Container>
	)
}

const DestinationContainer = styled.div`
	background-color: #333333;
	border-radius: 4px;
	padding: 14px;
	max-width: 260px;
	position: fixed;
	left: 1rem;
	top: 7rem;
`

const ProviderPhone = styled.a`
	font-size: 1em;
	margin-left: 1rem;
	color: #363636;

	& span {
		margin-left: 0.5rem;
	}

	&:hover {
		color: var(--fooy);
	}
`

const ProviderInfo = styled.div`
	margin-left: 1rem;
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	& h4 {
		font-size: 1.4em;
		text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
	}
`

const ProviderPhoto = styled.img`
	width: 4em;
	height: auto;
	border-radius: 50%;
`

const Provider = styled.div`
	display: flex;
	align-items: flex-start;

	margin: 0 1rem;
	max-width: 970px;
	font-size: 16px;
	width: 80%;

	@media (max-width: 420px) {
		font-size: 12px;
		width: 100%;
	}
`

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgba(255, 255, 255, 0.9);
	position: absolute;
	right: 0;
	left: 0;
	top: 0;

	opacity: 0;
	padding: 10px 0;
	transition: all 400ms ease;
	transform: translateY(-101%);

	&.visible {
		opacity: 1;
		transform: translateY(0);
	}
`

const Container = styled.div`
	display: flex;
	background: var(--fooy);
	position: relative;
	height: 100vh;
	width: 100vw;
`
