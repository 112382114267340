import styled from 'styled-components';

export const Item = styled.li`
	position: relative;
	background: #1f2128;
	padding: 10px;
	/* margin-bottom: 10px; */
	border-radius: 4px;
`

export const FooterContainer = styled.div`
	display: flex;
	justify-content: space-between;
	position: relative;
	cursor: pointer;

	& > svg {
		position: absolute;
		bottom: -8px;
		left: 50%;
		transform: translateX(-50%);
		transition: all 200ms ease;
	}

	&:hover > svg {
		color: #424242;
	}
`

export const PictureContainer = styled.div`
	height: 50px;
	width: 50px;
	border-radius: 50%;
	overflow: hidden;
	margin-right: 14px;
	background-color: #DDD;

	& img {
		position: relative;
		top: 50%;
		transform: translateY(-50%);
	}
`

export const MapButton = styled.button`
	border: none;
	outline: none;
	appearance: none;
	background: none;
	color: var(--fooy);
	font-size: 1rem;
	cursor: pointer;
	border-radius: 50%;
	padding: 0.6rem;

	width: 3rem;
	height: 3rem;
	position: absolute;
	top: 2rem;
	right: 0;

	& svg {
		transition: transform 500ms ease;
	}

	&:hover {
		background: #eee;
	}

	&:hover svg {
		transform: scale(1.5);
	}
`
export const WhatsAppButtonStyle = styled.button`
	border: none;
	outline: none;
	appearance: none;
	background: none;
	color: var(--fooy);
	font-size: 1rem;
	cursor: pointer;
	border-radius: 50%;
	padding: 0.6rem;
	z-index: 1;

	width: 3rem;
	height: 3rem;
	position: absolute;
	top: 2rem;
	right: 3rem;

	& svg {
		transition: transform 500ms ease;
	}

	&:hover {
		background: #eee;
	}

	&:hover svg {
		transform: scale(1.5);
	}
`
