export interface Destination {
	address: string
	coordinates: [number, number]
  description: string
  external_id: string
	type: string
	user_name: string
  user_phone: string
  invoice: {payment_type: PaymentType, price: number, money_change: number}
	_id: string
}
export enum PaymentType {
  ONLINE = "card",
  CASH = "cash",
  FACE_CREDIT = "face_credit",
  FACE_DEBIT ="face_debit"
}

export interface ServiceType {
	cityid: string
	cityname: string
	created_at: string
	typeid: string
	updated_at: string
	_id: string
}

export interface Vehicle {
	accessibility?: null
	admin_type_id: string
	color: string
	is_approved: boolean
	is_selected: boolean
	is_vehicle_documents_expired: boolean
	model: string
	name: string
	passing_year: string
	plate_no: string
	service_type: ServiceType[]
}

export interface Provider {
	accepted_request: number
	account_id: string
	address: string
	address_complement: string
	address_number: string
	admintypeid?: string
	app_version: string
	bank_id: string
	bearing: number
	bio: string
	birthdate: string
	cancelled_request: number
	car_model: string
	car_number: string
	city: string
	cityid: string
	completed_request: number
	country: string
	country_id: string
	country_phone_code: string
	created_at: string
	current_vehicle: Vehicle
	delayed_plan: false
	device_timezone: string
	device_token: string
	device_type: string
	device_unique_code: string
	document: string
	email: string
	emitter: string
	emitter_date: string
	fcm_token: string
	first_name: string
	full_cityname: string
	gender: string
	group_name: string
	is_active: number
	is_approved: number
	is_available: number
	is_disabled: number
	is_document_uploaded: number
	is_documents_expired: boolean
	is_partner_approved_by_admin: number
	is_trip: []
	is_use_google_distance: boolean
	is_vehicle_document_uploaded: boolean
	languages: []
	last_name: string
	location_updated_time: string
	login_by: string
	moobank: {}
	moobank_error: string
	neighbourhood: string
	password: string
	phone: string
	picture: string
	plan: { name: string; type: string; trip_percentage: number; max_value: number }
	providerLocation: [number, number]
	providerPreviousLocation: [number, number]
	provider_type: number
	provider_type_id?: string
	rate: number
	rate_count: number
	received_trip_from_gender: []
	referral_code: string
	// referred_by: {}
	refferal_credit: number
	register_origin: string
	rejected_request: number
	rg: string
	service_type: string
	social_unique_id: string
	start_online_time: null | string
	state: string
	token: string
	total_referrals: 0
	total_request: number
	unique_id: number
	updated_at: string
	vehicle_detail: Vehicle[]
	wallet: number
	wallet_currency_code: string
	zipcode: string
	__v: number
	_id: string
}

export interface Trip {
	acceptance_bonus_paid: boolean
	acceptance_distance_bonus: number
	accepted_time: string
	accessibility: []
	admin_currency: string
	admin_currencycode: string
	base_distance_cost: number
	bonus: number
	bonus_for_accept_distant_trips: boolean
	cancel_reason: string
	card_payment: number
	cash_payment: number
	charged_from_provider: number
	city_id: string
	cloudcash: number
	company_cost_center_id: string
	company_id: string
	company_payment_account_id: string
	confirmed_provider: string
	country_id: string
	created_at: string
	currency: string
	currencycode: string
	current_provider: string
	current_rate: number
	destinationLocation: [number, number]
	destination_address: string
	destinations: Destination[]
	distance_accepted: number
	distance_cost: number
	estimate_used: boolean
	estimated_distance: number
	estimated_fare: number
	estimated_time: number
	fare_warning: boolean
	fixed_price: number
	floor: number
	invoice_number: string
	is_amount_refund: boolean
	is_cancellation_fee: number
	is_delivery_completed: number
	is_fixed_fare: boolean
	is_min_fare_used: number
	is_moobi_tax_fee_registered: boolean
	is_paid: number
	is_pending_payments: number
	is_provider_accepted: number
	is_provider_earning_set_in_wallet: boolean
	is_provider_invoice_show: number
	is_provider_rated: number
	is_shared: boolean
	is_surge_hours: number
	is_tip: boolean
	is_toll: boolean
	is_trip_cancelled: number
	is_trip_cancelled_by_provider: number
	is_trip_cancelled_by_user: number
	is_trip_completed: number
	is_trip_end: number
	is_user_invoice_show: number
	is_user_rated: number
	long_distance_fee: number
	maximum_distance_to_accept: number
	moobi_tax_fee: number
	pay_to_provider: number
	payment_error: string
	payment_error_message: string
	payment_id: number
	payment_mode: number
	payment_transaction: []
	phrase: string
	promo_payment: number
	promo_referral_amount: number
	provider: Provider
	providerLocation: [number, number]
	providerPreviousLocation: [number, number]
	provider_arrived_time: string
	provider_first_name: string
	provider_have_cash: number
	provider_id: string
	provider_income_set_in_wallet: number
	provider_language: []
	provider_last_name: string
	provider_miscellaneous_fee: number
	provider_service_fees: number
	provider_service_fees_in_admin_currency: number
	provider_tax_fee: number
	provider_trip_end_time: string
	provider_trip_start_time: string
	provider_type: number
	providers_id_that_rejected_trip: []
	providers_receiving_trip: [string]
	received_trip_from_gender: []
	referral_payment: number
	refund_amount: number
	remaining_payment: number
	room_number: string
	schedule_start_time: string
	service_total_in_admin_currency: number
	service_type_id: string
	sourceLocation: [number, number]
	source_address: string
	status: number
	surge_fee: number
	surge_multiplier: number
	tax_fee: number
	time_cost: number
	timezone: string
	tip_amount: number
	toll_amount: number
	total: number
	total_after_promo_payment: number
	total_after_referral_payment: number
	total_after_surge_fees: number
	total_after_tax_fees: number
	total_after_user_balance_payment: number
	total_after_wallet_payment: number
	total_distance: number
	total_for_company: number
	total_in_admin_currency: number
	total_service_fees: number
	total_time: number
	total_waiting_time: number
	transferred_to_provider: false
	trip_service_city_type_id: string
	trip_type: number
	trip_type_amount: number
	type: string
	type_id: string
	type_name: string
	unique_id: number
	unit: number
	updated_at: string
	user_balance_payment: number
	user_create_time: string
	user_first_name: string
	user_id: string
	user_last_name: string
	user_miscellaneous_fee: number
	user_tax_fee: number
	user_type: number
	user_type_id: string
	uuid: string
	validation_transaction_id: string
	value_transferred_to_provider: number
	waiting_time_cost: number
	wallet_current_rate: number
	wallet_payment: number
	__v: number
	_id: string
}

export interface ITrip {
	_id: string
	unique_id: string
	address: string
	tripDate: string
	providerName: string
	providerPhone: string
	providerPicture: string
	providerCarModel: string
	tripPrice: string
  tripStatus: number
  is_delivery_completed: number
	type_name: string
	destinations: Destination[]
}
