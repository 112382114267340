import React, { useCallback } from 'react'
import { ITrip } from '../../../context/history/history.type'
import { FaAngleDown, FaMap, FaHandPaper, FaStar } from 'react-icons/fa'
import { useToggle } from '../../../lib/hooks'
import { ModalConfirm } from '../ModalConfirm'
import { ModalRating } from '../ModelRating'
import { useHistoryContext } from '../../../context/history'
import { Item, ItemContainer, ItemLink, Menu, Trigger, Wrapper } from './styles'

interface Props {
	trip: ITrip
	// cancelTrip(): void
	// finishTrip(): void
}

export const HistoryOptions = ({ trip }: Props) => {
	const { active, toggle, disabled } = useToggle(false)
	const { finishTrip, cancelTrip } = useHistoryContext()

	const cancelAction = useCallback(() => {
		cancelTrip(trip._id)
		disabled()
	}, [trip, cancelTrip])

	const finishAction = useCallback(
		(rating: number, callback: () => void) => {
			if (!!rating) {
				finishTrip(trip._id, rating, callback)
				disabled()
			}
		},
		[trip, finishTrip]
	)

	const isDisabled = trip.tripStatus >= 6
	const needRelease = trip.tripStatus == 8
	const canFinish = trip.tripStatus >= 8
	return (
		<Wrapper>
			<Trigger onClick={toggle}>
				<span>Opções</span>
				<FaAngleDown />
			</Trigger>
			{active && (
				<Menu>
					<ItemContainer>
						<ItemLink target="_blank" href={`/track/${trip._id}`}>
							<FaMap />
							<span>Rastrear</span>
						</ItemLink>

						{!isDisabled && (<ModalConfirm
							title="Confirmar cancelamento?"
							action={cancelAction}
							trigger={prop => (
								<Item onClick={prop.action} className="negative">
									<FaHandPaper />
									<span>Cancelar</span>
								</Item>
							)}
						/>)}

            {canFinish && (<ModalRating
							action={finishAction}
							trigger={prop => (
								<Item onClick={prop.action} className="positive">
									<FaStar />
									{needRelease ? (<span>Liberar entregador</span>) : (<span>Avaliar entrega</span>)}
								</Item>
							)}
            />)}
					</ItemContainer>
				</Menu>
			)}
		</Wrapper>
	)
}
