import React, { useCallback } from 'react'
import { cx } from '../../../lib/classnames'
import styled from '@emotion/styled'
import { Container, Star } from './styles'

interface Prop {
	updateCallback(rating: number): void
}

interface State {
	rating: number
	temp_rating: number
}

export class Rating extends React.PureComponent<Prop, State> {
	state = {
		rating: 0,
		temp_rating: 0
	}

	rate = (rating: any) => {
		this.setState(
			{
				rating: rating,
				temp_rating: rating
			},
			() => this.props.updateCallback(this.state.rating)
		)
	}

	star_over = (rating: any) => {
		this.setState(prev => ({
			temp_rating: prev.rating,
			rating
		}))
	}

	star_out = () => {
		this.setState(prev => ({ rating: prev.temp_rating }))
	}

	renderStars = () => {
		const stars = []

		for (let i = 1; i < 6; i++) {
			stars.push(
				<Star
					key={i}
					title={`${i} Estrela`}
					className={cx({ 'is-selected': this.state.rating >= i })}
					onClick={() => this.rate(i)}
					onMouseOver={() => this.star_over(i)}
					onMouseOut={this.star_out}
				>
					★
				</Star>
			)
		}

		return stars
	}

	render() {
		return <Container>{this.renderStars()}</Container>
	}
}
