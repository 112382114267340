import styled from 'styled-components';

export const Container = styled.div`
	height: 38px;
	display: flex;
	align-items: center;
	border-radius: 8px;
	border: 2px solid var(--fooy);
	overflow: hidden;
`

export const Label = styled.label`
	flex: 1;
	height: 100%;
	font-size: 1.1rem;
	padding: 0 0.5rem;
	cursor: pointer;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	color: #9e9e9e;

	&.active {
		background: var(--fooy);
		color: #fff;
	}

	&:not(:last-child) {
		border-right: 2px solid var(--fooy);
	}

	& span {
		margin-left: 0.5rem;
		padding: 0.4rem;
	}
`