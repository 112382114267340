import React, { useEffect, useMemo } from 'react'
import { FaAngleDown } from 'react-icons/fa'
import { useToggle } from '../../lib/hooks'
import { Item, ItemContainer, Menu, Trigger, Wrapper } from './styles'

interface Option {
	label: string
	id: string
}

interface Props {
	label: string
	value?: any
	options: Option[]
	onChange(id: string): void
}

export const Dropdown = React.memo(({ onChange, options, label, value }: Props) => {
	const { disabled, active, toggle } = useToggle(false)

	const selectedValue = useMemo(() => {
		return value ? options.find(o => o.id === value)!.label : null
	}, [value, options])

	useEffect(() => {
		if (active) document.addEventListener('click', disabled)
		return () => document.removeEventListener('click', disabled)
	}, [active])

	return (
		<Wrapper>
			<Trigger onClick={toggle}>
				<span>{selectedValue || label}</span>
				<FaAngleDown />
			</Trigger>
			<Menu active={active}>
				<ItemContainer>
					{options.map(option => (
						<Item key={option.id} onClick={() => onChange(option.id)} className="dropdown-item">
							{option.label}
						</Item>
					))}
				</ItemContainer>
			</Menu>
		</Wrapper>
	)
})


