import React, { useCallback } from 'react'
import Autosuggest from 'react-autosuggest'
import { FaMapMarker } from 'react-icons/fa'
import { usePlaceSuggestion, Suggestion } from '../../context/places'
import { CustomInput } from '../Input'
import { cx } from '../../lib/classnames'

import style from './placeInput.module.css'

interface Props {
	value: string
	placeholder?: string
	icon?: React.ReactNode
	disabled?: boolean
	onChange(e: any, target: { newValue: string; method: string }): void
	onSelect(suggestion: Suggestion): void
}

export const PlaceInput = ({ onSelect, ...props }: Props) => {
	const {
		state: { suggestions },
		getSuggestion,
		getSuggestions,
		clearSuggestions
	} = usePlaceSuggestion()

	const onSelectSuggestion = useCallback(
		(e: any, { suggestion }: Autosuggest.SuggestionSelectedEventData<Suggestion>) => {
			onSelect(suggestion)
		},
		[]
	)

	return (
		<Autosuggest
			suggestions={suggestions}
			renderInputComponent={renderInput}
			getSuggestionValue={getSuggestion}
			renderSuggestion={renderSuggestion}
			onSuggestionSelected={onSelectSuggestion}
			onSuggestionsFetchRequested={getSuggestions}
			onSuggestionsClearRequested={clearSuggestions}
			inputProps={props}
			theme={{
				container: style.container,
				suggestionsContainer: style.suggestion_container
			}}
		/>
	)
}

const renderInput = (inputProps: any) => (
	<CustomInput {...inputProps} required autoComplete="new-field" />
)

const renderSuggestion = (suggestion: Suggestion, _: { query: string; isHighlighted: boolean }) => (
	<div className={cx(style.suggestion, { [style.active]: _.isHighlighted })}>
		<FaMapMarker />
		<p className="subtitle is-7 has-text-weight-semibold is-marginless">{suggestion.description}</p>
	</div>
)
