import React from 'react'
import { Wrapper } from './styles'

interface Prop extends React.HTMLProps<HTMLDivElement> {}

export const Loading: React.SFC<Prop> = props => (
	<Wrapper {...props}>
		<div />
		<div />
		<div />
		<div />
	</Wrapper>
)