import React from 'react'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'
import LOGO from '../assets/logo.png'

export const LoadindScreen = () => (
	<Container>
		<Progress />
		<img src={LOGO} />
	</Container>
)

const Container = styled.div`
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	background: var(--fooy);
	position: relative;

	& img {
		width: 400px;
		height: auto;
	}
`

const Indeterminate = keyframes`
  0 % {
    left: -35%;
    right: 100%;
  }
  60 % {
    left: 100%;
    right: -90%;
  }
  100 % {
    left: 100%;
    right: -90%;
  }
`

const IndeterminateShort = keyframes`
  0% {
    left: -200%;
    right: 100%;
  }
  60% {
    left: 107%;
    right: -8%;
  }
  100% {
    left: 107%;
    right: -8%;
  }
`

const Progress = styled.div`
	background-color: #acece6;

	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	height: 4px;
	overflow: hidden;

	&::before {
		content: '';
		position: absolute;
		background-color: #26a69a;
		top: 0;
		left: 0;
		bottom: 0;
		will-change: left, right;
		animation: ${Indeterminate} 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
	}

	&::after {
		content: '';
		position: absolute;
		background-color: #26a69a;
		top: 0;
		left: 0;
		bottom: 0;
		will-change: left, right;
		animation-delay: 1.15s;
		animation: ${IndeterminateShort} 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
	}
`

// @keyframes indeterminate - short {
//   0 % {
//     left: -200 %;
//     right: 100 %;
//   }
//   60 % {
//     left: 107 %;
//     right: -8 %;
//   }
//   100 % {
//     left: 107 %;
//     right: -8 %;
//   }
// }
