import React, { useEffect, useRef } from 'react'
import { Switch, Route } from 'react-router-dom'
import { Order, History } from '../../container'
import { Navbar } from './Components/Navbar/Navbar'
import { MapContainer } from './Components/Map/Map'
import { DistanceModal } from './Components/DistanceModal/DistanceModal'
import { AdminFunctions } from './Components/AdminFunctions/AdminFunctions'
import { ProviderModal } from './Components/ProviderModal/ProviderModal'
import { Planned } from '../../container/planned'
import { Schedule } from '../../container/Schedule'
// import * as JivoSite from "react-jivosite";

import { Container, MapWrapper, Panel, PanelDelivers, SplitBar, WhatsAppButtonStyle } from './styles'
import styled from '@emotion/styled'
import { FaWhatsapp } from 'react-icons/fa'

export default () => {
	const split = useRef<HTMLDivElement>(null)
	const container = useRef<HTMLDivElement>(null)
	const dragging = useRef<boolean>(false)

	useEffect(() => {
		const mouseMove = (e: MouseEvent) => {
			if (e.clientX > 450) {
				container.current!.style.width = `${e.clientX}px`
			}
		}

		const mouseUp = (e: MouseEvent) => {
			dragging.current = false
			window.removeEventListener('mousemove', mouseMove)
			window.removeEventListener('mouseup', mouseUp)
		}

		const mouseDown = (e: MouseEvent) => {
			e.preventDefault()
			dragging.current = true
			window.addEventListener('mousemove', mouseMove)
			window.addEventListener('mouseup', mouseUp)
		}

		if (split.current && container.current) {
			split.current.addEventListener('mousedown', mouseDown)
		}

		return () => {
			split.current!.removeEventListener('mousedown', mouseDown)
		}
	}, [dragging])

	return (
		<Container>
			<Panel ref={container}>
				<Navbar />
				<Switch>
					<Route exact path="/" component={Order} />
					<Route path="/history" component={History} />
					<Route path="/planned" component={Planned} />
					<Route path="/schedule" component={Schedule} />
				</Switch>
				<SplitBar ref={split} />
			</Panel>
			{/* <PanelDelivers className="mobile-hidden">
				<History/>
			</PanelDelivers> */}
			<MapWrapper>
				<MapContainer />
				<DistanceModal />
				<ProviderModal />
				<AdminFunctions />
				{/* <WhatsAppButton onClick={() => window.open("https://wa.me/55", "_blank")}>Falar no Whatsapp</WhatsAppButton> */}
				<WhatsAppButtonStyle onClick={() => window.open("https://wa.me/5581996905791", "_blank")}>
		<FaWhatsapp/>
		<p>Fale conosco!</p>
	</WhatsAppButtonStyle>
			</MapWrapper>
			{/* <JivoSite.Widget id="h2PqTl6HU0" /> */}
	
		</Container>
	)
}

