import styled from '@emotion/styled'

export const SubmitButton = styled.button`
	height: 100%;
	width: 100%;
	border: none;
	border-radius: 0 4px 4px 0;
	font-size: 1.1em;
	font-weight: 600;
	color: white;
	padding: 1em;
	cursor: pointer;
	background: var(--fooy);
	transition: all 200ms;

	&:hover {
		opacity: 0.9;
	}

	&.loading {
		background: #e53935;
	}

	&:disabled {
		pointer-events: none;
		background: #757575;
	}

	&:active {
		transform: scale(0.85);
		box-shadow: inset 0px 0px 50px var(--fooy);
	}
`
