export function animateTo(marker: google.maps.Marker, newPosition: google.maps.LatLngLiteral) {
	const start_lat = marker.getPosition().lat()
	const start_lng = marker.getPosition().lng()
	const { lat: new_lat, lng: new_lng } = newPosition

	let animation = 0
	const animateStep = (marker: google.maps.Marker, startTime: any) => {
		const ellapsedTime = new Date().getTime() - startTime
		const durationRatio = ellapsedTime / 1500

		if (durationRatio < 1) {
			const deltaPosition = new google.maps.LatLng(
				start_lat + (new_lat - start_lat) * durationRatio,
				start_lng + (new_lng - start_lng) * durationRatio
			)

			marker.setPosition(deltaPosition)

			animation = window.requestAnimationFrame(() => {
				animateStep(marker, startTime)
			})
		} else {
			marker.setPosition(newPosition)
		}
	}

	window.cancelAnimationFrame(animation)
	animateStep(marker, new Date().getTime())
}
